.baron__clipper {
  position: relative;
  overflow: hidden;
}
.baron__scroller {
  overflow-y: scroll;
  -ms-overflow-style: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  /* remove line to customize scrollbar in iOs */
}
.baron__scroller::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.baron__track {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.baron._scrollbar .baron__track {
  display: block;
}
.baron__free {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.baron__bar {
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;
  width: 10px;
  background: #999;
}
.baron._scrollbar .baron__bar {
  display: block;
}
.baron__control {
  display: none;
}

/* LMS STYLE */
.baron._lms .baron__track {
  opacity: 0;
  position: absolute;
  z-index: 3;
  //top: 2px;
  //bottom: 2px;
  //right: 2px;
  top: 0;
  bottom: 0;
  right: 0;
  width: 0;
}
.baron._lms._scrollbar .baron__track {
  opacity: 1;
}
.baron._lms .baron__bar {
  position: absolute;
  z-index: 1;
  //width: 6px;
  width: 10px;
  //border-radius: 3px;
  border-radius: 2px;
  background: #333;
  //opacity: 0;
  opacity: .15;
  transition: .1s opacity .4s linear;
  pointer-events: auto;
}
.baron._dragging .baron__bar {
  /*opacity: 1;*/
}
.baron._lms .baron__bar:hover,
.baron._scrolling .baron__bar {
  transition-duration: 0s;
  transition-delay: 0s;
  opacity: .5;
}

.baron._lms .baron__bar:active,
.baron._scrolling .baron__bar {
  transition-duration: 0s;
  transition-delay: 0s;
  opacity: .5;
}
