.App {
    width: 100%;
    height: 100%;

    .Cabinet {
        width: 100%;
        height: calc(100% - 42px);

        .Page {
            margin-left: 280px;
            width: calc(100% - 280px);
            height: 100%;
        }
    }
}
