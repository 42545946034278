.Schedule {
    width: 100%;

    .Schedule__add {
        height: 42px;
        vertical-align: top;
        display: inline-block;
        margin-left: 5px;
        cursor: pointer;
        //margin-right: 42px;
        margin-bottom: 18px;

        &_disabled {
            opacity: .25;
            cursor: default;
        }

        .Add__text {
            //margin-top: 8px;
            margin-left: 12px;
            vertical-align: top;
            display: inline-block;

            //font-size: 15px;
            //font-weight: 700;
            //background-color: #2A3E4C;
            //color: white;
            //padding: 4px 6px 3px;
            //border-radius: 4px;

            margin-top: 10px;
            font-family: Roboto, sans-serif;
            //font-weight: 400;
            //color: #0066cc;
            font-size: 19px;
            //color: #2A3E4C;
            font-weight: 500;
            //opacity: .25;

            color: #0061ff;
            //opacity: .75;
        }
    }

    .Schedule_table {
        margin-bottom: 24px;
        width: 100%;
        border-collapse: collapse;
    }

    .Table__th {
        text-align: center;
        color: #2A3E4C;
        font-family: Roboto, sans-serif;
        font-size: 19px;
        font-weight: 700;
        padding: 14px 0;
        border: 2px solid #eaeaea;

        &_width-40 {
            width: 40px;
        }
    }

    .k {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAqCAYAAAC+5wuXAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAANvSURBVEhLrZZZSJRRFMfPjEEqFeGLpa0IJbmkpaaO2bRAai/lUmRli5RIIb4IPgwk9JBFi0lkGlqYGOXSk6MtMiYWlg9hjlvTUD1kGKSF6fRm879zv5lv5t6ZUegH39xzl+/Mueece+6nsdls8yTh28QkWSyfSb8rmfXfDQxSePgqCg8LZX1PtLx1o7GpnY4VlFLFpVt8hMj0qp+NYU6GoMjYaaKGBy28J4I5rPFEVNTVw9rMDD21P6lhMig5f5LOnMpjsrJGjaDIPPyRtbmHMigkZCWTQVBQIOlStjNZWaNG6iMwZ/vLJReyMYUAg8FQwWXG7KyNRkYtNDg4ardiKWm1Wpqe/k39b9/TnbtNNPNnlvJysigpcSt/w4EQfqv1KxUWlfOenPraSoqIWM97DqR5NGQep9p7zYIvoqM2UdHZfIqJ3sxHXHhNSADr4Jdgu6OBpxVq/Gb23JyN9YODgyg2JtItkmqkipC93pLyQnEB5eZk8p4LIfytbZ0+M/t2TaM0swWL8k+U0sT3SadjFf/AV49bOqjv9QCFrQ6l5odVbFxBsAhKgBIdOBgP5CN5B9icskaNoAiWAMUSNcpYmi6RtWoERbAEdD3vZeFXPxgDqcnxwpzgI/2+o1xaHF4P7WIRLJKFdiEIiqamfnnNXl8IW8s+XExXrtWyYm/zUX888ets1J7EhFiWR6iS3hAUwZpOSU1GNmfsT6f4uKiFlxFsCTVpbNxKXc96hUxWlO7do3Pec1JFaqC0w2hih1UGbhvcMFJFiNyHoTEaHrFQS5uRj7pQjpFSQeFHQZHh4g12wj3By/r0ZEpJ2ebcDkqOYukS9qtCrcSfg+PjtnDJS/hhavrOJOnLauC/CXtJDrSnheaT9cu8+gsDk77yBfPwW8HxbD7iQIsvDOwVC4AvJSgXZeWXpaWYHRE4rPBcOTsWMvAn+DNcnLJ7HwSsWRfJrmxcxS+6+2jyx0/auGEtrVi+jC1AYpaVV1K36Q3rA+ROWmoC7znQmM1j861Pu4RjgU+YmZlZtzxCFEtLTgv3PnBGDfu/Wd3g1XREEsq9+VAIP/xUVX3feb583fdqhHoEs+vrKnmPKMvuD39KgKat3SicNXD1eh1r4dgYfrbUZGXu5pIDzQ7dQakif/S8fMQlB//vFkH4ubwo3L+ViP4BxXaycBeBiSYAAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        height: 42px;
        width: 18px;
        margin-left: 12px;
        padding-right: 8px;
    }

    .s {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAABNCAYAAACrFjR1AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAATaSURBVFhH1Zh9TFV1GMcfqaVgrkCaQdGLFPSiokt8w0ywJTBrDbNVK9d6kWE22yqBNbfbWpNsbtAoRq6X+YJrqLNmQE6BWmiJbCIoqVErCucfuTaE6392v8/9PZdzz/M7516YbvbZ2O+5v/M7D7/ze17PmRAMBi+Ti78Hz9O+rw9Qx+EuGjx3nufS06ZR4fIltKK4gFJSbuY5J0oRlLxdvimiwA0UfvhBJd2WPs3MhEkwY4S6+p0RJYvzcqmocCn/QQa4tqNhH8tO1I6WPvosj+vKVtNTK4tYFpqa22jzlk9Zbj+4i0dB7UiYM/sBI42SnTWdRzyeG6XopRdX8fjPhX95dCJzJU8u59HJ9WaMkJSYyGP1R19QTs79LAvd3X08Hj/RR/2//8my4HlGY8XzjMaK1SHHg1IEE4MF8+dYPdgLzzP6rL6KMjPvZDkert4ZyY7gT6lTk1m2UVyUb6Qw4zZ/3CEyVjx3FNi4njJuT2PZhtsQ/wOrXTGHBMHgJfr56HE6eeosvVb2As/t3tNMqanJNH/ebEpMnMRzTtSjQcn7VZ9Q4L0aatzTZGaJ0wbmcA1r3ChFuPnHjk6WZzyYxSNIu/UWHnHN+Q8Epajlux94RM6urXmXZbD6+RJ2CSBrnChFUkFsOVv8ylaqlCJJ7AN/nePRiczZkr+y2rYde+nzLxtZRj2bac6p5+QZam5pZxkBjUd1ohRdCFWKdesDvpW2tiagfMzqR1C2v6k1sjMBO4m79ruBz9gc0I2noqOd3dR57ARdHB6hzLvv4PINpb/2/0EzZ2SbVaOoAimeLU4JcOjOeVtmUOZvaz8SpUTA42XdexfLLQficMgmY2J4tjud5i18iMe4QqQ35C/A5tl+eHq2rRs5feY3HuNqa9AnAnQjyEFgYGCQZWmyZI2Tq+vZaEjRJ0psCchPleVrVSMKfD0bCi+ZbDgpZH6bAiFmiMSLUnTtlmwBcWaj/K1SI4VRij6u2x4VAjA32uGC/IW+BdN62Ih0FMiDrYejAhivEU+sWMZpxJ2jYloNLnCotYNLkDgpdtmwvZplIeZhpyTfRPdlZ0Y17zav99xRfygTdhzpUjtBnC0ryFPOqRS1f/8T7d7bHEknAJbLf2QBzcvNMTMapchtfsRXRka6+TVKTPNfe549XnwVSRqJlUKAVVFP72mq39oQsRyshsNF0fxm/yF6p2Kt8mx1RvCf198IRJkfIGzQqSBkUPvcKEVS/GD2DW+uYRlgB9LKSO1zohRJ5Je++px6cZlqot+9W+Bp/iTXGYARk79tKEXyxQGPiHMRIH/V+C3LssaJshoss6Gyyvyys3lThYo7tSMskI8INnDNFryeDgk3wOOh0QI3Tk6i3LmzPDPAlQ8R1PzWtrCjub/SoIEYCQa5P/J6h+MzwgGXPF1GtXXb1DcPgDl0uC+XVvBaGwk4i1hWcoK1uMfNddPvmRU41XeW83H1lo30eKjcuFm8aC49HPKdY109NHRxmCZOvEGbHx/kwJpXnvF9h8U1rAFyj5MEqRB+b9SC79sRHgnYekY3fmsS8haFW170jLZDFJAtkY/AqpXFPDqZ0Nv7y2WYVUBATpky2fwKMzQ0HNUD2Dp/dsh4AlWwBSyIeLbElq2rB3icwseWeFrWGmtjaULDEP0HrIm0+jha1JUAAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        height: 77px;
        width: 18px;
        margin-left: 12px;
        padding-right: 8px;
    }

    .Table__td {
        text-align: center;
        color: #2A3E4C;
        font-family: Roboto, sans-serif;
        font-size: 17px;
        font-weight: 700;
        padding: 16px 0;
        border: 2px solid #eeeeee;
        min-width: 24px;

        &_big {
            font-family: Roboto, sans-serif;
            font-size: 24px;
            font-weight: 900;
            padding: 11px 0 12px;
        }

        &.default {
            background-color: #eeeeee;

            &.active {
                border: 2px solid #eaeaea;
            }
        }

        &.active {
            color: #0061ff;

            &:hover {
                color: #0061ff;
            }
        }

        &.pointer {
            cursor: pointer;
        }
    }

    .Schedule__info {
        //min-width: 1195px;
        min-width: 1434px;
        border-spacing: 0 24px;
        width: 100%;
        margin-bottom: 18px;

        .Info__tr {


            .Info__td {
                vertical-align: top;
                text-align: left;
            }
        }

        .Info__box {
            display: inline-block;

            .Box__logo {
                vertical-align: top;
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-size: 24px;
                font-weight: 900;
                border: 4px solid #eeeeee;
                text-align: center;
                width: 44px;
                padding: 8px 0 7px;
                margin-right: 12px;
                transition: ease .25s;
                border-radius: 9px;
                color: #2A3E4C;
            }

            .Box__text {
                padding-top: 17px;
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-size: 17px;
                font-weight: 400;
                min-width: 146px;
                margin-right: 12px;
                color: #2A3E4C;

                &_padding-7 {
                    padding-top: 7px;
                }
            }

            &.pointer {
                cursor: pointer;

                &:hover {

                    .Box__logo {
                        border: 4px solid #2A3E4C;
                        //color: #f7f7f7;
                        color: #2A3E4C;
                        //color: #0061ff;
                        //background-color: #0061ff;
                    }

                    .Box__text {
                        //color: #0061ff;
                    }
                }
            }

            &.active {
                cursor: default;

                .Box__logo {
                    border: 4px solid #2A3E4C;
                    //color: #f7f7f7;
                    color: #2A3E4C;
                    //color: #0061ff;
                    //background-color: #0061ff;
                }

                .Box__text {
                    //color: #0061ff;
                }
            }
        }
    }

    .Schedule__weeks {
        //min-width: 1195px;
        //min-width: 1434px;
        border-spacing: 0 24px;
        width: 100%;
        margin-bottom: 36px;

        .Info__tr {

            .Info__td {
                margin-right: 42px;
                display: inline-block;
                vertical-align: top;
                text-align: left;
            }
        }

        .Info__box {
            display: inline-block;

            &_default {
                opacity: .25;
            }

            .Box__logo {
                vertical-align: top;
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-size: 24px;
                font-weight: 900;
                border: 4px solid #eeeeee;
                text-align: center;
                width: 44px;
                padding: 8px 0 7px;
                margin-right: 12px;
                transition: ease .25s;
                border-radius: 9px;
                color: #2A3E4C;

                &.pointer {
                    cursor: pointer;

                    &:hover {
                        border: 4px solid #2A3E4C;
                    }
                }

                &.active {
                    cursor: default;
                    border: 4px solid #2A3E4C;
                }
            }

            .Box__text {
                padding-top: 17px;
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-size: 17px;
                font-weight: 400;
                //min-width: 146px;
                margin-right: 12px;
                color: #2A3E4C;

                &_padding-7 {
                    padding-top: 7px;
                }
            }

            &.pointer {
                cursor: pointer;

                &:hover {

                    .Box__logo {
                        border: 4px solid #2A3E4C;
                        //color: #f7f7f7;
                        //background-color: #2A3E4C;
                    }
                }
            }

            &.active {
                cursor: default;

                .Box__logo {
                    border: 4px solid #2A3E4C;
                    color: #f7f7f7;
                    background-color: #2A3E4C;
                }
            }
        }
    }

    .Schedule__period {
        width: 100%;
        margin-bottom: 24px;

        .Info__tr {
            margin-bottom: 12px;

            &_inline-block {
                display: inline-block;
            }

            .Info__td {
                display: inline-block;
                vertical-align: top;
                text-align: left;
                margin-right: 42px;
            }
        }

        .Item__hr {
            vertical-align: top;
            margin: 10px 16px 0 4px;

            &_margin-32 {
                margin: 0 32px;
            }

            &_vertical {
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUOE+F1O1LwkAcwPG7zamBhWmERIhIREiEoHPm9sK3lZp/avgQ1CtfOJ9HEiHhCxEJk5AYZaZuu4teFLXtznv9efH7cd87CNYcQcrHDKQtO86PR0izoVDaHdj3JjHW9aZcqlIxz58HWJfzSEfatFMrP1CxIFxGIAd3lxj17+TCmI7FjAShwzF5UhvDYWVBxJFI2rPl88YxNhZNudj43o2IBeFsH3KuA80wJkq9+LgG504gx/jmaN67r92+0DDDp3ISwBi26+8yABWdiKPRvNftAVGE0awlFzo/d2E7My9dhFnABQ2gjdrV8oCKE2IuzkDGs5iBbrd7pVJw2sGfbooAQtyuFaoAAETEsVh2h9tgjw0E1Hbtqvu3HcvMicT5IeN07mlYHyhyaUTFgphNQsi6317VTq9XmRGxOUlzvv/GMCdJxeYk6diUJBHbJUnEMTET5KAjjFarcat13bd7m78L8ql8lGWAV/s0HhSlOKVh2yRtxyAlaYtJSdpiUpI2mJykBdOStGBakhZMS9KCv7P0+13binLzvO77/QK1APGlKBSmpwAAAABJRU5ErkJggg==);
                background-repeat: no-repeat;
                height: 32px;
                width: 11px;
            }

            &_inline {
                display: inline-block;
            }
        }

        .Info__box {
            vertical-align: top;
            display: inline-block;

            &_default {
                opacity: .25;
            }

            .Box__logo {
                vertical-align: top;
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-size: 24px;
                font-weight: 900;
                border: 4px solid #eeeeee;
                text-align: center;
                width: 44px;
                padding: 8px 0 7px;
                color: #2A3E4C;
                transition: ease .25s;
                border-radius: 9px;
                margin-right: 12px;

                &.pointer {
                    cursor: pointer;

                    &:hover {
                        border: 4px solid #2A3E4C;
                    }
                }

                &.active {
                    cursor: default;
                    border: 4px solid #2A3E4C;
                    color: #f7f7f7;
                    background-color: #2A3E4C;

                    &:hover {
                        //box-shadow: 0 16px 20px 0 rgba(0, 0, 0, 0.14);
                    }
                }
            }

            .Box__text {
                //padding-top: 17px;
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-size: 17px;
                font-weight: 400;
                //min-width: 146px;
                margin-right: 12px;
                color: #2A3E4C;

                vertical-align: top;
                display: inline-block;
                margin-top: 8px;
                font-family: Roboto, sans-serif;
                font-size: 19px;
                font-weight: 500;
                color: #2A3E4C;
                opacity: .25;
                padding-top: 8px;

                &_padding-7 {
                    padding-top: 7px;
                }
            }

            &.pointer {
                cursor: pointer;

                &:hover {

                    .Box__logo {
                        border: 4px solid #2A3E4C;
                        color: #f7f7f7;
                        background-color: #2A3E4C;
                    }
                }
            }

            &.active {
                cursor: default;

                .Box__logo {
                    border: 4px solid #2A3E4C;
                    color: #f7f7f7;
                    background-color: #2A3E4C;
                }
            }
        }
    }

    .Schedule__week {
        width: 100%;
        margin-bottom: 24px;

        .Info__tr {
            margin-bottom: 12px;

            &_inline-block {
                display: inline-block;
            }

            .Info__td {
                display: inline-block;
                vertical-align: top;
                text-align: left;
                margin-right: 12px;
            }
        }

        .Item__hr {
            vertical-align: top;
            margin: 10px 16px 0 4px;

            &_margin-32 {
                margin: 0 32px;
            }

            &_vertical {
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUOE+F1O1LwkAcwPG7zamBhWmERIhIREiEoHPm9sK3lZp/avgQ1CtfOJ9HEiHhCxEJk5AYZaZuu4teFLXtznv9efH7cd87CNYcQcrHDKQtO86PR0izoVDaHdj3JjHW9aZcqlIxz58HWJfzSEfatFMrP1CxIFxGIAd3lxj17+TCmI7FjAShwzF5UhvDYWVBxJFI2rPl88YxNhZNudj43o2IBeFsH3KuA80wJkq9+LgG504gx/jmaN67r92+0DDDp3ISwBi26+8yABWdiKPRvNftAVGE0awlFzo/d2E7My9dhFnABQ2gjdrV8oCKE2IuzkDGs5iBbrd7pVJw2sGfbooAQtyuFaoAAETEsVh2h9tgjw0E1Hbtqvu3HcvMicT5IeN07mlYHyhyaUTFgphNQsi6317VTq9XmRGxOUlzvv/GMCdJxeYk6diUJBHbJUnEMTET5KAjjFarcat13bd7m78L8ql8lGWAV/s0HhSlOKVh2yRtxyAlaYtJSdpiUpI2mJykBdOStGBakhZMS9KCv7P0+13binLzvO77/QK1APGlKBSmpwAAAABJRU5ErkJggg==);
                background-repeat: no-repeat;
                height: 32px;
                width: 11px;
            }

            &_inline {
                display: inline-block;
            }
        }

        .Info__box {
            vertical-align: top;
            display: inline-block;

            &_default {
                opacity: .25;
            }

            .Box__logo {
                vertical-align: top;
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-size: 20px;
                font-weight: 900;
                border: 4px solid #eeeeee;
                text-align: center;
                width: 44px;
                padding: 11px 0 9px;
                color: #2A3E4C;
                transition: ease .25s;
                border-radius: 9px;

                &.pointer {
                    cursor: pointer;

                    &:hover {
                        border: 4px solid #2A3E4C;
                    }
                }

                &.active {
                    //cursor: default;
                    border: 4px solid #2A3E4C;
                }
            }

            .Box__text {
                //padding-top: 17px;
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-size: 17px;
                font-weight: 400;
                min-width: 146px;
                margin-right: 12px;
                color: #2A3E4C;

                vertical-align: top;
                display: inline-block;
                margin-top: 8px;
                font-family: Roboto, sans-serif;
                font-size: 19px;
                font-weight: 500;
                color: #2A3E4C;
                opacity: .25;
                padding-top: 8px;

                &_padding-7 {
                    padding-top: 7px;
                }
            }

            &.pointer {
                cursor: pointer;

                &:hover {

                    .Box__logo {
                        border: 4px solid #2A3E4C;
                        //color: #f7f7f7;
                        //background-color: #2A3E4C;
                    }
                }
            }

            &.active {
                //cursor: default;

                .Box__logo {
                    border: 4px solid #2A3E4C;
                    color: #f7f7f7;
                    background-color: #2A3E4C;

                }
            }
        }
    }

    .Schedule__hours {
        width: 100%;
        //margin-bottom: 28px;
        margin-bottom: 4px;

        .Info__tr {
            //margin-bottom: 12px;

            &_inline-block {
                display: inline-block;
            }

            .Info__td {
                display: inline-block;
                vertical-align: top;
                text-align: left;
                margin-right: 12px;
            }
        }

        .Item__hr {
            vertical-align: top;
            margin: 10px 16px 0 4px;

            &_margin-32 {
                margin: 0 32px;
            }

            &_vertical {
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUOE+F1O1LwkAcwPG7zamBhWmERIhIREiEoHPm9sK3lZp/avgQ1CtfOJ9HEiHhCxEJk5AYZaZuu4teFLXtznv9efH7cd87CNYcQcrHDKQtO86PR0izoVDaHdj3JjHW9aZcqlIxz58HWJfzSEfatFMrP1CxIFxGIAd3lxj17+TCmI7FjAShwzF5UhvDYWVBxJFI2rPl88YxNhZNudj43o2IBeFsH3KuA80wJkq9+LgG504gx/jmaN67r92+0DDDp3ISwBi26+8yABWdiKPRvNftAVGE0awlFzo/d2E7My9dhFnABQ2gjdrV8oCKE2IuzkDGs5iBbrd7pVJw2sGfbooAQtyuFaoAAETEsVh2h9tgjw0E1Hbtqvu3HcvMicT5IeN07mlYHyhyaUTFgphNQsi6317VTq9XmRGxOUlzvv/GMCdJxeYk6diUJBHbJUnEMTET5KAjjFarcat13bd7m78L8ql8lGWAV/s0HhSlOKVh2yRtxyAlaYtJSdpiUpI2mJykBdOStGBakhZMS9KCv7P0+13binLzvO77/QK1APGlKBSmpwAAAABJRU5ErkJggg==);
                background-repeat: no-repeat;
                height: 32px;
                width: 11px;
            }

            &_inline {
                display: inline-block;
            }
        }

        .Info__box {
            margin-bottom: 12px;
            vertical-align: top;
            display: inline-block;

            &_default {
                opacity: .25;
            }

            .Box__logo {
                vertical-align: top;
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-size: 24px;
                font-weight: 900;
                border: 4px solid #eeeeee;
                text-align: center;
                width: 44px;
                padding: 8px 0 7px;
                color: #2A3E4C;
                transition: ease .25s;
                border-radius: 9px;

                &.pointer {
                    cursor: pointer;

                    &:hover {
                        border: 4px solid #2A3E4C;
                    }
                }

                &.active {
                    cursor: pointer;
                    color: #f7f7f7;
                    border: 4px solid #2A3E4C;
                    background-color: #2A3E4C;
                }
            }

            .Box__text {
                //padding-top: 17px;
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-size: 17px;
                font-weight: 400;
                min-width: 146px;
                margin-right: 12px;
                color: #2A3E4C;

                vertical-align: top;
                display: inline-block;
                margin-top: 8px;
                font-family: Roboto, sans-serif;
                font-size: 19px;
                font-weight: 500;
                color: #2A3E4C;
                opacity: .25;
                padding-top: 8px;

                &_padding-7 {
                    padding-top: 7px;
                }
            }

            &.pointer {
                cursor: pointer;

                &:hover {

                    .Box__logo {
                        border: 4px solid #2A3E4C;
                        //color: #f7f7f7;
                        //background-color: #2A3E4C;
                    }
                }
            }

            &.active {
                //cursor: default;

                .Box__logo {
                    border: 4px solid #2A3E4C;
                    color: #f7f7f7;
                    background-color: #2A3E4C;
                }
            }
        }
    }
}

.Schedule__switch {
    display: inline-block;
    margin: 0 0 0 9px;
    //float: right;

    .Switch__text {
            //margin-top: 8px;
            vertical-align: top;
            display: inline-block;

            //font-size: 15px;
            //font-weight: 700;
            //background-color: #2A3E4C;
            //color: white;
            //padding: 4px 6px 3px;
            //border-radius: 4px;

            margin-top: 8px;
            font-family: Roboto, sans-serif;
            //font-weight: 400;
            //color: #0066cc;
            font-size: 19px;
            //color: #2A3E4C;
            font-weight: 500;
            //opacity: .25;

            color: #2A3E4C;
            opacity: .25;
    }

    .Item__hr {
        vertical-align: top;
        margin: 2px 16px 0;

        &_margin-32 {
            margin: 0 32px;
        }

        &_vertical {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUOE+F1O1LwkAcwPG7zamBhWmERIhIREiEoHPm9sK3lZp/avgQ1CtfOJ9HEiHhCxEJk5AYZaZuu4teFLXtznv9efH7cd87CNYcQcrHDKQtO86PR0izoVDaHdj3JjHW9aZcqlIxz58HWJfzSEfatFMrP1CxIFxGIAd3lxj17+TCmI7FjAShwzF5UhvDYWVBxJFI2rPl88YxNhZNudj43o2IBeFsH3KuA80wJkq9+LgG504gx/jmaN67r92+0DDDp3ISwBi26+8yABWdiKPRvNftAVGE0awlFzo/d2E7My9dhFnABQ2gjdrV8oCKE2IuzkDGs5iBbrd7pVJw2sGfbooAQtyuFaoAAETEsVh2h9tgjw0E1Hbtqvu3HcvMicT5IeN07mlYHyhyaUTFgphNQsi6317VTq9XmRGxOUlzvv/GMCdJxeYk6diUJBHbJUnEMTET5KAjjFarcat13bd7m78L8ql8lGWAV/s0HhSlOKVh2yRtxyAlaYtJSdpiUpI2mJykBdOStGBakhZMS9KCv7P0+13binLzvO77/QK1APGlKBSmpwAAAABJRU5ErkJggg==);
            background-repeat: no-repeat;
            height: 32px;
            width: 11px;
        }

        &_inline {
            display: inline-block;
        }
    }

    .Switch__wrapper {
        vertical-align: top;
        display: inline-block;
        margin: 2px 0 0;
    }

    .switch {
        display: inline-block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        tap-highlight-color: transparent;
    }

    .switch__lever {
        position: relative;
        display: inline-block;
        width: 42px;
        height: 32px;
        //background: #FF0B0B;
        //background: #dce1e4;
        background-color: #c4c9cd;
        border-radius: 16px;
        transition: all 0.40s cubic-bezier(.17,.67,.43,.98);
    }

    .switch__lever::after {
        position: absolute;
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 12px;
        top: 6px;
        left: 6px;
        background: #f7f7f7;
        transition: all 0.25s ease-out;
    }

    .switch__index {
        vertical-align: top;
        //font: 400 18px/32px 'Roboto', sans-serif;
        font: 400 16px/32px 'Roboto', sans-serif;
        margin-left: 16px;
    }

    .switch__index::after {
        transition: .37s;
    }

    .switch__index::after {
        color: #cacfd3;
        //color: #FF0B0B;
        //color: #2A3E4C;
        //opacity: 0.2;
        content: "Отключен";
    }

    .switch_active {}

    .switch_active .switch__lever {
        background: #4CAF50;
    }

    .switch_active .switch__lever:after {
        left: 16px;
    }

    .switch .switch__lever:after {
        box-shadow: 0 7px 27px -9px rgba(0,0,0,.5);
    }

    .switch .switch__lever:hover {
        //background: #dee2e4;
        //background: #FF0B0B;
        background-color: rgba(42, 62, 76, 0.30);
        //background-color: #cdced2;
    }

    .switch_active .switch__lever:hover {
        background-color: #3ba03f;
    }

    .switch_active .switch__index::after {
        color: #4CAF50;
        content: "Включен";
    }

}