.StudentFeedback {
  height: 100%;

  .StudentFeedback__body {
    height: 100%;

    .Body__wrapper {
      width: 460px + 266px + 550px + 42px + 42px + 42px;
      height: 100%;
    }

    .Body__title {
      color: #2a3e4c;
      font-family: Roboto, sans-serif;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 24px;
    }

    .Body__label {
      color: #2a3e4c;
      font-family: Roboto, sans-serif;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 24px;
    }

    .Body__hr {
      width: 100%;
      height: 2px;
      margin-bottom: 42px;
      border-radius: 1px;
      background-color: #EAEAEA;

      &_vertical {
        height: 100%;
        width: 2px;
        margin: 0 42px;
        display: inline-block;
        float: right;
      }
    }

    .Body__list {
      width: 100%;
      margin-bottom: 42px;

      .List__item {
        position: relative;
        height: 39px;
        transition: ease .25s;
        color: #2a3e4c;
        font-family: Roboto, sans-serif;
        font-size: 19px;
        font-weight: 700;
        background-color: #EEEEEE;
        border-radius: 7px;
        padding: 17px 32px 16px 24px;
        cursor: pointer;
        margin-bottom: 16px;

        &:hover {
          background-color: #ffffff;
          box-shadow: 0 16px 20px rgba(0, 0, 0, 0.07);
        }

        .Item__index {
          position: absolute;
          top: -12px;
          //color: #2A3E4C;
          font-family: 'Roboto', sans-serif;
          font-size: 15px;
          font-weight: 700;
          //opacity: .25;
          background-color: #2A3E4C;
          color: white;
          padding: 4px 6px 3px;
          border-radius: 4px;
        }

        .Item__wrapper {
          width: 100%;

          .Item__left {
            float: left;
            display: inline-block;
          }

          .Item__right {
            float: right;
            display: inline-block;
          }
        }

        .Item__block {
          vertical-align: top;
          font-family: 'Roboto', sans-serif;
          font-size: 19px;
          font-weight: 700;
          color: #2A3E4C;

          &_inline {
            display: inline-block;
          }

          &_margin-left {
            margin-left: 32px;
          }

          &_padding {
            padding-top: 8px;
          }
        }

        .Item__label {
          color: #2A3E4C;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          opacity: .25;

          &_inline {
            font-size: 19px;
            vertical-align: super;
            display: inline-block;
          }

          &_block {
            font-size: 17px;
            display: block;
            margin-bottom: 6px;
          }

          &_mini {
            padding-top: 2px;
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 6px;
          }
        }

        .Item__Text {
          display: inline-block;
          vertical-align: super;
          font-family: 'Roboto', sans-serif;
          font-size: 19px;
          font-weight: 700;
          color: #2A3E4C;
        }

        .Item__hr {
          margin: 0 16px;

          &_margin-32 {
            margin: 0 32px;
          }

          &_vertical {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUOE+F1O1LwkAcwPG7zamBhWmERIhIREiEoHPm9sK3lZp/avgQ1CtfOJ9HEiHhCxEJk5AYZaZuu4teFLXtznv9efH7cd87CNYcQcrHDKQtO86PR0izoVDaHdj3JjHW9aZcqlIxz58HWJfzSEfatFMrP1CxIFxGIAd3lxj17+TCmI7FjAShwzF5UhvDYWVBxJFI2rPl88YxNhZNudj43o2IBeFsH3KuA80wJkq9+LgG504gx/jmaN67r92+0DDDp3ISwBi26+8yABWdiKPRvNftAVGE0awlFzo/d2E7My9dhFnABQ2gjdrV8oCKE2IuzkDGs5iBbrd7pVJw2sGfbooAQtyuFaoAAETEsVh2h9tgjw0E1Hbtqvu3HcvMicT5IeN07mlYHyhyaUTFgphNQsi6317VTq9XmRGxOUlzvv/GMCdJxeYk6diUJBHbJUnEMTET5KAjjFarcat13bd7m78L8ql8lGWAV/s0HhSlOKVh2yRtxyAlaYtJSdpiUpI2mJykBdOStGBakhZMS9KCv7P0+13binLzvO77/QK1APGlKBSmpwAAAABJRU5ErkJggg==);
            background-repeat: no-repeat;
            height: 32px;
            width: 11px;
          }

          &_inline {
            display: inline-block;
          }
        }
      }
    }

    .Body__table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0 0;
      margin: 24px 0 0;

      .Table__tr {
        cursor: pointer;
        transition: ease .25s;

        &:hover {
          box-shadow: none;

          .Table__td {
            background-color: #EEEEEE;
          }
        }

        .Table__td {
          color: #373b50;
          font-family: Roboto, sans-serif;
          font-size: 17px;
          font-weight: 400;
          height: 56px;
          padding: 0 24px;

          transition: ease .25s;

          &:first-child {
            padding: 0 16px;
            width: 36px;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
          }

          &:nth-child(2) {
            width: 30%;
            padding: 0 24px 0 0;
          }

          &:nth-child(3) {
            width: 30%;
            padding: 0 24px 0 0;
          }

          &:last-child {
            padding: 0 24px;
            width: 16px;
            border-bottom-right-radius: 7px;
            border-top-right-radius: 7px;
          }
        }
      }
    }
  }
}

.Block__Item {
  margin-bottom: 36px;

  .Item__wrapper {
    width: 100%;

    .Item__left {
      float: left;
      display: inline-block;
    }

    .Item__right {
      float: right;
      display: inline-block;
    }
  }

  .Item__block {
    font-family: 'Roboto', sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #2A3E4C;

    &_inline {
      display: inline-block;
    }

    &_margin-right {
      margin-right: 56px;
    }

    &_padding {
      padding-top: 8px;
    }
  }

  .Item__label {
    color: #2A3E4C;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    opacity: .25;

    &_inline {
      font-size: 19px;
      vertical-align: super;
      display: inline-block;
    }

    &_block {
      font-size: 17px;
      display: block;
      margin-bottom: 6px;
    }

    &_mini {
      padding-top: 2px;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 6px;
    }
  }

  .Item__Text {
    display: inline-block;
    vertical-align: super;
    font-family: 'Roboto', sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #2A3E4C;
  }

  .Item__hr {
    margin: 0 16px;

    &_margin-32 {
      margin: 0 32px;
    }

    &_vertical {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUOE+F1O1LwkAcwPG7zamBhWmERIhIREiEoHPm9sK3lZp/avgQ1CtfOJ9HEiHhCxEJk5AYZaZuu4teFLXtznv9efH7cd87CNYcQcrHDKQtO86PR0izoVDaHdj3JjHW9aZcqlIxz58HWJfzSEfatFMrP1CxIFxGIAd3lxj17+TCmI7FjAShwzF5UhvDYWVBxJFI2rPl88YxNhZNudj43o2IBeFsH3KuA80wJkq9+LgG504gx/jmaN67r92+0DDDp3ISwBi26+8yABWdiKPRvNftAVGE0awlFzo/d2E7My9dhFnABQ2gjdrV8oCKE2IuzkDGs5iBbrd7pVJw2sGfbooAQtyuFaoAAETEsVh2h9tgjw0E1Hbtqvu3HcvMicT5IeN07mlYHyhyaUTFgphNQsi6317VTq9XmRGxOUlzvv/GMCdJxeYk6diUJBHbJUnEMTET5KAjjFarcat13bd7m78L8ql8lGWAV/s0HhSlOKVh2yRtxyAlaYtJSdpiUpI2mJykBdOStGBakhZMS9KCv7P0+13binLzvO77/QK1APGlKBSmpwAAAABJRU5ErkJggg==);
      background-repeat: no-repeat;
      height: 32px;
      width: 11px;
    }

    &_inline {
      display: inline-block;
    }
  }
}


.Chat__messages {
  display: inline-block;
  vertical-align: top;
  //width: 550px;
  //width: 594px;
  width: 460px;
  height: calc(100%);
  margin-right: 42px;

  .Course__header {
    height: 100%;
    margin-bottom: 42px;

    &_margin-bottom-16 {
      margin-bottom: 16px;
    }

    &_margin-bottom-38 {
      margin-bottom: 38px;
    }

    .Header__title {
      position: relative;
      color: #2A3E4C;
      cursor: default;

      .Title__text {
        padding-top: 0;
        display: inline-block;
        vertical-align: top;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        font-size: 32px;
        margin-right: 24px;
        padding-right: 42px;
      }

      .Title__date {
        margin-top: 12px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 18px;
      }

      .Button__edit {
        position: absolute;
        top: 0;
        right: 0;
        vertical-align: top;
        height: 42px;
        display: inline-block;
        opacity: 0.5;
        cursor: pointer;
        transition: ease .25s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .Course__wrapper {
    height: calc(100% - 38px - 24px - 2px - 42px - 8px);
    overflow-y: hidden;
    //height: 100%;
  }

  .Course__info {

    margin-bottom: 24px;
    background-color: #eee;
    border-radius: 7px;
    //width: calc(100% - 48px);
    padding: 24px;
    position: relative;
    cursor: pointer;

    transition: ease .25s;

    .Student__online {
      transition: ease .25s;
      border: 3px solid #eee;
    }

    &:hover {
      background-color: #ffffff;
      //box-shadow: 0 16px 20px rgba(0, 0, 0, 0.07);

      .Student__online {

        border: 3px solid white;
      }
    }

    &.active {
      cursor: default;
      background-color: #ffffff;

      .Student__online {

        border: 3px solid white;
      }
    }

    .Info__title {
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 24px;
      color: #2A3E4C;
      margin-bottom: 24px;
    }

    .Info__text {
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 17px;
      color: #2A3E4C;
      opacity: .5;
      margin-bottom: 24px;
    }

    .Info__link {


      .Link__text {
        margin-right: 24px;
        display: inline-block;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 17px;
        color: #0061FF;
        cursor: pointer;
      }
    }

    .Info__edit {
      position: absolute;
      top: 16px;
      right: 16px;
      vertical-align: top;
      height: 42px;
      display: inline-block;
      opacity: 0.5;
      cursor: pointer;
      transition: ease .25s;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.Chat {
  display: inline-block;
  vertical-align: top;
  width: 720px;
  margin-right: 42px;
  //padding-left: 42px;
  height: calc(100%);
  //border-left: 2px solid #EAEAEA;
  position: relative;
  //background-color: white;

  .Chat__room {
    height: calc(100% - 56px - 42px);
    width: 100%;
    overflow-y: hidden;


    &._after::after {
      z-index: 1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 96px;
      /*background-image: -webkit-linear-gradient(top, #1c2b36, rgba(28, 43, 54, 0));*/
      /*background-image: linear-gradient(to bottom, #1c2b36, rgba(28, 43, 54, 0));*/
      background-image: -webkit-linear-gradient(top, #f7f7f7, rgba(247, 247, 247, 0));
      background-image: linear-gradient(to bottom, #f7f7f7, rgba(247, 247, 247, 0));
      pointer-events: none;
    }
  }

  .Chat__send {
    position: absolute;
    bottom: 0;
    height: 56px;
    width: 100%;
  }

}

.Day {
  display: inline-block;
  vertical-align: top;
  width: 222px;
  margin-right: 42px;
  padding-left: 42px;
  height: calc(100%);
  border-left: 2px solid #EAEAEA;



  .Box__day {
    color: #2A3E4C;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 96px;
    margin-bottom: 32px;
  }

  .Box__today {
    margin-left: 16px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: left;
    color: #2A3E4C;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;


    .Today__weekday {
      position: absolute;
      top: 36px;
      font-size: 18px;
    }

    .Today__month {
      position: absolute;
      top: 58px;
      font-size: 18px;
    }
  }

  .Box__timetable {
    margin-bottom: 12px;

    &_pointer {
      cursor: pointer;
    }


    .Timetable__text {
      width: 190px;
      //height: 56px;
      background-color: #eaeaea;
      border-radius: 9px;
      padding: 11px 16px 9px;

      .Text__time {
        vertical-align: top;
        display: inline-block;
        color: #2A3E4C;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 28px;
        padding: 0;
      }

      .Item__hr {
        margin: 0 16px;

        &_margin-32 {
          margin: 0 32px;
        }

        &_vertical {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUOE+F1O1LwkAcwPG7zamBhWmERIhIREiEoHPm9sK3lZp/avgQ1CtfOJ9HEiHhCxEJk5AYZaZuu4teFLXtznv9efH7cd87CNYcQcrHDKQtO86PR0izoVDaHdj3JjHW9aZcqlIxz58HWJfzSEfatFMrP1CxIFxGIAd3lxj17+TCmI7FjAShwzF5UhvDYWVBxJFI2rPl88YxNhZNudj43o2IBeFsH3KuA80wJkq9+LgG504gx/jmaN67r92+0DDDp3ISwBi26+8yABWdiKPRvNftAVGE0awlFzo/d2E7My9dhFnABQ2gjdrV8oCKE2IuzkDGs5iBbrd7pVJw2sGfbooAQtyuFaoAAETEsVh2h9tgjw0E1Hbtqvu3HcvMicT5IeN07mlYHyhyaUTFgphNQsi6317VTq9XmRGxOUlzvv/GMCdJxeYk6diUJBHbJUnEMTET5KAjjFarcat13bd7m78L8ql8lGWAV/s0HhSlOKVh2yRtxyAlaYtJSdpiUpI2mJykBdOStGBakhZMS9KCv7P0+13binLzvO77/QK1APGlKBSmpwAAAABJRU5ErkJggg==);
          background-repeat: no-repeat;
          height: 32px;
          width: 11px;
        }

        &_white {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAATFJREFUOE+F1DEuRFEYhuH3ayiJDWAFWAGi0I5KdNOh02EFRqUdDdHp0OmwAMEKjEQtlDSf/FNMMnP/c+5p71N8Ofe9V7Qc28/AJ9BVzdpeAN6Bb2CuDXeBS+BOUqcNXwPbwL6kfhHbjmdfwCywKGlQw8vAC/AhKbZTwwfAGXAlKbZX8T2wCexIiu05tj0N/ABTwyuT4uqKeA14AN4kxfbhSTfb7gGHwKmkozb8CiwB65Iei9h23Gvc7x8wI+m3hjvADfAkKbaPTmOz7T6wCxxLiu1VPADmgRVJsT3HjSQl1/BYkpOtj222PZZkEWdJ1nAjyRqO13oCnEvay77N0Wbb8VpXgS1Jt0VcSjKdYTtNsoTTJEs4TbKBa0lmuJhkhotJZriYZIbjb7MBXGgiyUn8D7Y3oy302I40AAAAAElFTkSuQmCC);
        }

        &_inline {
          display: inline-block;
        }
      }
    }
  }
}


.Calendar {
  display: inline-block;
  vertical-align: top;

  .Calendar__day {
    position: relative;
    color: #2A3E4C;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 96px;
  }

  .Calendar__today {
    //margin-left: 16px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: left;
    color: #2A3E4C;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;

    .Today__weekday {
      position: absolute;
      top: 18px;
      left: 2px;
      font-size: 18px;
    }

    .Today__month {
      position: absolute;
      top: 30px;
      font-weight: 700;
      font-size: 64px;
    }
  }


  .Header__buttons {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    vertical-align: bottom;
    margin-left: 12px;

    .Header__button {
      margin: 0 8px;
      display: inline-block;
      cursor: pointer;
      opacity: .5;
      transition: ease .3s;
      height: 32px;
      width: 32px;

      &:hover {
        opacity: 1;
      }

      &_left {
        height: 32px;
        width: 32px;
        background-position: center center;
        background-repeat: no-repeat;
        //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAVRJREFUOE+V1L9Lw0AUB/B3T5qmURAnM3asdVMKWYQOQkXBSfeugrvgX+BfIR0dC0q6Blt0cMmkUZfg1NGhYKDJi1zapPlx+dFsIfl83927xzGo+LS0k6YjO1PbMJyQsDLb7HZlxVP6wFAlIAf/YPD+pk+5K8RxGBaJB+RiEYwCfLKtsT4Q4iLIA3h161m/y+AyGGACw5o8GQlcBfq+Z36MR8NEw3YOj7d3N6UrBJTzTiAOI8yh2qhfM2S1qjDCraOzW2RMWgcGeE/rHTCpdp4HPfJ+Pieje9F3xpsku8oNImY6T0SAiDB3afj9opvpgAC0O719qm9c8h+jQVjC8F0UEFVbBlzwFRAArGJW9dIBiaW2O6cqNaBfdFzxgMw+1wkQznZZQO5shzssCijFi1MQ98Cds4ev10er9CZJBxQ2TDRJi0Ha0pzZzLRN47fyHVZ0x/0Dzgu7FxCrzPIAAAAASUVORK5CYII=);
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAArtJREFUWEfFV01PE1EUPXemdafgwq+4sJiYuFLcmLgwlERdaGIgujFCpMGdIcIvoP0FgI27NqUpBjdCN2WhGEpYkLABdKEJCfQHGC1RF868mWvelKn9hHnTiUwyySRz7z3nnffuxyN4fKYymW6NT/QR8wAxIkyIEBCR7gyUiFFiki/lbTJWJ2KxspfQdJTRVGY+otvWJAEjR9nW/mdg1tL0xETsSekwv7YE5Ip1OyyBx1WAm20pLjRjpp0iLQlI8JAVXgGhtzPwA2/GltDN/lYkmgi8zrzptWx7hUDdgYBXOXBZ17T+F7GnW7Vx6whUZA/tBQ3uAjK4bGmip1aJKoHAZW8nX8N2VAkkU7lpEL0MUvb2sTgxNjocl/8dAjLVQra193/AKyhC03tkijoEkqm5WRCe+SFw88Y1pxRtbH5Wc2dkx54PjZCz93b4h5p3xfrO7Vu4euWy8/1lZxcf19aVwgjNPE3JVG4ARItKng3gru/y2jq+7ux6D8U8SH7kr125i2YYJubzBfz89VuBALL0Kp0rEqjPq9ejB/dw4dyZOnMJvrD0Ad++q+0kg1elAiUQLnkhMPz4IbpOnQwE3AnC2KJkeo69gA/ev4uL58/WmZqmwLvCe+WV1wbpiIBhmlgoqEtfT6DDLfhjGFhcWvapAm8Hcgj9knAPoXIVbJWGksTb/JJ6Gh57IQqqFMsKKCuhyjM2OkQBNSNgY/OTCrasAZVmJL2OvR07LTmdiwM0qbYMv9YNA0lFBdmWQ0WArvsN682Pt4Umou5c2DSUhqyw7A1d3oIpWjH2hW5GWg6lbig5ltsWFwMnwdjXdIoeOpa7JILfjnrZ63pBOxEPSIx3fjA5ITQxrXQ1qyXlpKhlxZWHVkZW6Hrc9+W0UZnKxSUUBWiAieXVvPtfxvA2A2ViKgGcF7ooer2e/wVG+WZG9crseQAAAABJRU5ErkJggg==);
      }

      &_right {
        margin: 0 0 0 6px;
        height: 32px;
        width: 32px;
        background-position: center center;
        background-repeat: no-repeat;
        //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAUhJREFUOE9jZEACCgYOApxcXPzXj217iCyOi80Ik1C18jJgZmHwYGJg4vj//++F64e3byBkAFizmqWvBgvr/whkxcQYwAhyKgcfVwbIRnSbCBnAqGHhocDExpKAy4n4DAA7W9PWM4CRkdmAVAPgAUaOAXDNRLng37/X149smwpzIYpmYgz4++/fu5tHtk0CqcXQTIwB/6EuwKoZZIC6jVceMxOTEK5AfP71Rz9em//9+8fAxMSEof/f//+/bhze2kbQz+gG/P/378+L778mvz+75yNRoQ0zAFkjSoARiuc///6/+sXydd6DAwd+oESVho2PAxMTgwPOXPSf4cU35i8LkDWCbVZwcODg+MdVgC1jgA3DoZGwZjwa4X4GFQSsLEwBKM4moBElwFAMIEIjRvIE5W1GZkaJ76zfL6AHDrbAxJk8CZVfIHkAVde7risMDRIAAAAASUVORK5CYII=);
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAspJREFUWEfNV0tPE1EU/s486kKNYNxbTExcKW5MXBggARewKbiRiJEGd4YIv4DyC0Abd21KU4wbFWKiCx+hxIUJG6guNCGBbnwgC4vu4M4cc6e0tExb5s5MjJPM6p5zvu+ec8+L4PGbyWTaNI50EXOMGFEmRAmISnUGisQoMsmfFm3aXZ6Mx0teTNNRQjOZJ1HdtqYIGD1KtvacgTlL06cn48PFVnpNCcgb67YpgSdUgN2ylBDa7oNmHmlIQIIblrkEQmcw8H1txprQ93oakXAReJR53GnZ9hKB2kIBr3Lgkq5pPffit9Zq7dYRKLvd2AwbvALI4JKliY5aT1QJhO72Zu47FI4qgWQqNwui+2G6vbktnh4fu52Q5w4BmWqGbW3+G/AyitD0DpmiDoFkan4OhDsqBK5cvuiUoJXVTypqB7KM7PjdkVFyYm+bv1Ss9F67igvnzzkqn9c38O79BxX1qqzQ9topmcrFQLTg1YIElgRqP98kmAdJ1f0nTxzHcGwAkYgZnAQjSw/TuTyBurx6QMqdOd2Oof4+F4lvW9t4/vK1Z1MMXpYeKIJw1rPWvmAzEju//yD39IU3c4w1Sqbn2Zu0W0qSuDFwHaZp1B1+/fETC6/eeDIbmMDQQB8iZv17UCMQIASD/b04FonU3VQpBOCC70fYCPz71jae+XiESlVQpuHNWL/r5l/WN/BWtSDJNAyjEPkCl4GThShoKfYNDmB8bIQCNiNgZfWjp3RzCVWa0X/Rjp2WnM4lAJrydx1VrUMDSdkLsi0beYAuqZpTk+eC0ER3ZS50DaWGZcrecErNqEdpxo7Q96INh9KKCTmW2xbnQyfB2NF06m45lldIhB+OerfX+qvlambYxkTwh8nTQhOzSqtZLUNnYrashOrQCkZW6HrC93J6+FmVFxejG6AYE8vVvO0gY7jAQImYigAvCl3kva7nfwGHj05GAviNwQAAAABJRU5ErkJggg==);
      }
    }
  }


  .Calendar__table {
    //margin: 0 0 56px;
    padding: 0;
    //width: 790px;
    border-collapse: separate;
    border-spacing: 4px 3px;
    margin-bottom: 16px;

    .Calendar__tr {
      //width: 790px;
      //margin: 0;
      //padding: 0;
      //background-color: #F0F3F5;

      .Calendar__th {
        position: relative;
        //margin: 0;
        //padding: 16px 0 0;
        //width: 24px;
        color: #2A3E4C;
        //background-color: #F0F3F5;
        //border: 2px solid #E6EAED;
        cursor: default;
        //text-align: center;
        text-align: left;

        .Th__title {
          display: inline-block;
          padding: 0 0 1px 5px;
          font-family: Roboto, sans-serif;
          font-size: 17px;
          color: #2A3E4C;
          font-weight: 900;
          opacity: .25;
        }
      }

      .Calendar__td {
        vertical-align: middle;
        position: relative;
        //margin: 0;
        //width: 24px;
        //min-height: 56px;
        //padding: 15px 0 16px;
        color: #61717B;
        //border: 2px solid #E6EAED;
        //cursor: pointer;
        //text-align: center;

        .Box__markers {
          position: absolute;
          bottom: 16px;
          height: 8px;
          width: 100%;
          text-align: center;
          padding-bottom: 8px;


          .Box__marker {
            //position: absolute;
            //top: 24px;
            //right: 24px;
            display: inline-block;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            margin: 0 2px;

            &_red {
              background-color: #F44336;
              border: 1px solid #2a3e4c;
            }

            &_yellow {
              //background-color: #FFC107;
              //background-color: #E9C33E;
              background-color: #fdd835;
              border: 2px solid #2a3e4c;
            }

            &_gray {
              background-color: #c2c8cd;
              border: 2px solid #c2c8cd;
            }

            &_green {
              //background-color: #87B789;
              background-color: #66bb6a;
              border: 2px solid #2a3e4c;
            }

            &_purple {
              //background-color: #b7879d;
              background-color: #00acc1;
              border: 2px solid #2a3e4c;
            }

            &_blue {
              //background-color: #b7879d;
              background-color: #0061ff;
              border: 2px solid #0061ff;
            }
          }
        }

        .Td__text {
          position: relative;
          width: 72px;
          height: 56px;
          //vertical-align: inherit;
          //display: inline-block;
          //text-overflow: ellipsis;
          //overflow: hidden;
          //white-space: nowrap;
          //word-wrap: break-word;
          //padding: 29px 0 24px;
          font-family: Roboto, sans-serif;
          font-size: 24px;
          color: #2A3E4C;
          font-weight: 900;
          border-radius: 9px;
          background-color: #eaeaea;
          cursor: pointer;

          &_default {
            background-color: #eaeaea;
            cursor: default;
            opacity: .25;
          }

          &_pointer {
            cursor: pointer;
          }

          .Text__day {
            display: inline-block;
            padding: 6px 0 0 12px;
          }

          .Text__week {
            display: inline-block;
            font-family: Roboto, sans-serif;
            font-size: 13px;
            font-weight: 900;
            float: right;
            padding: 9px 10px 0 0;
            opacity: .25;
          }

          &.today {
            color: #0061ff;
          }
        }

        &.selected {
          .Td__text {
            background-color: #2A3E4C;
            cursor: default;
            color: white;

            &_default {
              background-color: #2A3E4C;
              cursor: default;
              opacity: 1;
            }

            &.today {
              color: white;
              background-color: #0061ff;
            }

            .Box__markers {
              .Box__marker {
                &_yellow {
                  //background-color: #FFC107;
                  //background-color: #E9C33E;
                  background-color: #fdd835;
                  border: 2px solid #fdd835;
                }

                &_gray {
                  background-color: #c2c8cd;
                  border: 2px solid #c2c8cd;
                }

                &_green {
                  //background-color: #87B789;
                  background-color: #66bb6a;
                  border: 2px solid #66bb6a;
                }

                &_purple {
                  //background-color: #b7879d;
                  background-color: #00acc1;
                  border: 2px solid #00acc1;
                }
                &_blue {
                  //background-color: #b7879d;
                  background-color: white;
                  border: 2px solid white;
                }
              }
            }
          }


        }
      }

      &:hover {
        color: #2A3E4C;
      }
    }
  }

  .Schedule__info {
    //min-width: 1195px;
    //min-width: 1434px;
    border-spacing: 4px 24px;
    width: 100%;
    margin-bottom: 18px;

    .Info__tr {

      .Info__td {
        //vertical-align: top;
        //text-align: left;
        margin-bottom: 16px;
      }
    }

    .Info__box {
      display: inline-block;

      .Box__logo {
        vertical-align: top;
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-weight: 900;
        border: 4px solid #eeeeee;
        text-align: center;
        width: 44px;
        padding: 8px 0 7px;
        margin-right: 12px;
        transition: ease .25s;
        border-radius: 9px;
        color: #2A3E4C;

        &.yellow {
          //border-color: #E9C33E;
          //background-color: #E9C33E;

          background-color: #fdd835;
          border-color: #fdd835;
          //color: #73642d;
        }

        &.green {
          //border-color: #87B789;
          //background-color: #87B789;
          //color: #455f46;
          background-color: #66bb6a;
          border-color: #66bb6a;
        }

        &.purple {
          background-color: #00acc1;
          border-color: #00acc1;
          //border-color: #b7879d;
          //background-color: #b7879d;
          //color: #455f46;
        }
      }

      .Box__text {
        padding-top: 17px;
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-size: 17px;
        font-weight: 400;
        min-width: 146px;
        margin-right: 12px;
        color: #2A3E4C;

        &_padding-7 {
          padding-top: 7px;
        }
      }

      .Item__label {
        color: #2A3E4C;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        opacity: .25;

        &_inline {
          font-size: 19px;
          vertical-align: super;
          display: inline-block;
        }

        &_block {
          font-size: 17px;
          display: block;
          margin-bottom: 6px;
        }

        &_mini {
          padding-top: 2px;
          font-size: 15px;
          font-weight: 700;
          margin-bottom: 6px;
        }
      }

      .Item__Text {
        display: inline-block;
        vertical-align: super;
        font-family: 'Roboto', sans-serif;
        font-size: 19px;
        font-weight: 700;
        color: #2A3E4C;
      }

      .Item__hr {
        margin: 0 16px;

        &_margin-32 {
          margin: 0 32px;
        }

        &_vertical {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUOE+F1O1LwkAcwPG7zamBhWmERIhIREiEoHPm9sK3lZp/avgQ1CtfOJ9HEiHhCxEJk5AYZaZuu4teFLXtznv9efH7cd87CNYcQcrHDKQtO86PR0izoVDaHdj3JjHW9aZcqlIxz58HWJfzSEfatFMrP1CxIFxGIAd3lxj17+TCmI7FjAShwzF5UhvDYWVBxJFI2rPl88YxNhZNudj43o2IBeFsH3KuA80wJkq9+LgG504gx/jmaN67r92+0DDDp3ISwBi26+8yABWdiKPRvNftAVGE0awlFzo/d2E7My9dhFnABQ2gjdrV8oCKE2IuzkDGs5iBbrd7pVJw2sGfbooAQtyuFaoAAETEsVh2h9tgjw0E1Hbtqvu3HcvMicT5IeN07mlYHyhyaUTFgphNQsi6317VTq9XmRGxOUlzvv/GMCdJxeYk6diUJBHbJUnEMTET5KAjjFarcat13bd7m78L8ql8lGWAV/s0HhSlOKVh2yRtxyAlaYtJSdpiUpI2mJykBdOStGBakhZMS9KCv7P0+13binLzvO77/QK1APGlKBSmpwAAAABJRU5ErkJggg==);
          background-repeat: no-repeat;
          height: 32px;
          width: 11px;
        }

        &_inline {
          display: inline-block;
        }
      }

      &.pointer {
        cursor: pointer;

        &:hover {

          .Box__logo {
            //border: 4px solid #2A3E4C;
            //color: #f7f7f7;
            color: #2A3E4C;
            //color: #0061ff;
            //background-color: #0061ff;

            &.yellow {
              //border: 4px solid #c3a435;
              //background-color: #c3a435;

              border: 4px solid #2A3E4C;
              background-color: #2A3E4C;
              color: white;
            }

            &.green {
              //border: 4px solid #658867;
              //background-color: #658867;

              border: 4px solid #2A3E4C;
              background-color: #2A3E4C;
              color: white;
            }

            &.purple {
              border: 4px solid #2A3E4C;
              background-color: #2A3E4C;
              color: white;
            }
          }

          .Box__text {
            //color: #0061ff;
          }
        }
      }

      &.active {
        cursor: default;

        .Box__logo {
          //border: 4px solid #2A3E4C;
          //color: #f7f7f7;
          color: #2A3E4C;
          //color: white;
          //color: #0061ff;
          //background-color: #0061ff;

          &.yellow {
            //border: 4px solid #c3a435;
            //background-color: #c3a435;
            //border: 4px solid #9c873b;
            //background-color: #9c873b;
            //color: #9c873b;

            border: 4px solid #2A3E4C;
            background-color: #2A3E4C;
            color: white;
          }

          &.green {
            //border: 4px solid #658867;
            //background-color: #658867;
            //border: 4px solid #455f46;
            //background-color: #455f46;
            //color: #455f46;

            border: 4px solid #2A3E4C;
            background-color: #2A3E4C;
            color: white;
          }

          &.purple {
            border: 4px solid #2A3E4C;
            background-color: #2A3E4C;
            color: white;
          }

          &:hover {
            &.yellow {
              //border: 4px solid #c3a435;
              //background-color: #c3a435;
              //border: 4px solid #9c873b;
              //background-color: #9c873b;
              //color: #9c873b;

              border: 4px solid #2A3E4C;
              background-color: #2A3E4C;
              color: white;
            }

            &.green {
              //border: 4px solid #658867;
              //background-color: #658867;
              //border: 4px solid #455f46;
              //background-color: #455f46;
              //color: #455f46;

              border: 4px solid #2A3E4C;
              background-color: #2A3E4C;
              color: white;
            }
          }
        }
      }
    }
  }
}

.Input__chat {
  &::-webkit-input-placeholder { /* Chrome */
    color: #2a3e4c;
    opacity: .25;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #2a3e4c;
    opacity: .25;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #2a3e4c;
    opacity: .25;
  }
  &:-moz-placeholder { /* Firefox 4 - 18 */
    color: #2a3e4c;
    opacity: .25;
  }
}
