.Form__section {
    width: 100%;
    margin: auto;

    .Section__header {

        .Header__photo {
            display: inline-block;
            position: relative;
            margin-bottom: 16px;

            .photo {
                position: absolute;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABHNCSVQICAgIfAhkiAAAAyZJREFUWEfNmD9sUkEcx7831kWMro04ORZca1K6uEI3t9J2NREGdQRGdRAT1wpd1K2wughJXQVGJ9GuGnHR8cz3vXv4eNy93z2KSS8htOF3v/vc79/97hQyDq11DkAZQAUA/+anYNSMAcwATAEMAPSVUvzfeyhfSa01AR4CKPnOMXIEayml+C0OEUhrTYDGCiDJxb3AUoG01h0AVXFb2QTaSqm6a4oVyMTJ6Rqs4lqX1tqzxdcSkIH5EAvUbPv3l2YC7CahbECESQ3c6XegPwJ6n4DZb2B8HlIUNoHcFaByBygXgfwNkW6glNqNSy0ASTFDkFYf6H4UFwoEqttAoyyCvVRK1SKNcyCTTbSOddAaB8fA7I8fTCSV2wA6R6HVUgZdF5SFOJDTVd0z4OB1NpCkdOcQqN516pi7LgAyRY9ZtTTWARMpPX2QaqnAShEQzbWTpGHMFBvZ3eSyA903ajljaqiUKimT5j9tSqrHwIlnAPs6dH8b6B45pa8RiJWYFXlh0Dq3HsvLRJnE9K+/BQaf5TlfnjmtdECgnjm9FzS13wP1d7Ly6XPg5vVQbvwNKDblOS/uA7V7VrkTAlnjp/QUGHrsVieyTx3KQDu3gcETq9yQQCPbMZF/BHz9YVcuFTxaKs19rOgMbssYE0jbfknbadxNLntI7ktaNtLz34Am50CBXZRjpAHx1N1KzpNc1qz8C+bkXMLU3rgzbmsTGNtdNrmUQd0FsJ/c5SppL7kpWkNK+wsXRrqPhTHNTfENS4WR15jLc3SQ3FUceXwwU35l7IFcmXV1IwxmRycZHq4GiC2rtTljY7b3Sq6+PhLe7UealfjbOnoioUELrMO1MrWwbEeyuo9uYruRuYU1Vmqb67LVA4ypZt+/R2Lv01y1yY8IXAEeJyQYY6s3CtOd9YeDFTi4BhVDi3hcg+aump9lSVOYDpItydJx4hO4GWQmvP+JF0XjOtYmNm5LfXaGBdNEh3zO8bpKx7VorVNjakW4hYthUofvcwwb04tai1ZpSu9EIlAs2FknVgHzAnEGteQGE/R8TSNg3jzpRQnAQI0/6fWyPun9BYxsXU1cwNIPAAAAAElFTkSuQmCC);
                background-repeat: no-repeat;
                width: 36px;
                height: 36px;
                top: -6px;
                right: -6px;

                cursor: pointer;

                &_del {
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABHNCSVQICAgIfAhkiAAAA1dJREFUWEfNmD1MFEEUx/+DgrAHBAu0FEsTE21QOkBKEz8KojZ+hFgIJoeJYkLj2VigxZ1BLMAINmosBBNLlOtQCiEx2gmUBhMJcHfK15j/unu525vZ2cUlMsnl7rLz8dv3/vPmvREI2aSUdQBOAzgDgL/5OepMMw1gEcAcgAkAY0II/g/cRNCeUkoCxAG0BB3j9CPYXSEEv43NCCSlJMCdLYB4Fw8E5gskpXwK4LLxtcJ1SAohbuiGKIEcnbyOwCq6dWmtsyp9lQA5MO8LhBru/YP35gZo9UKpgAjjK9zNhe9Ym/qAtalJyEwGG/OzNsauAwchYjGUNzahvPE4yur3m/AmhBCthZ2KgEyaIcivVy+wmh43LWQ/r2huQ2X7eRNYSgjR7U6YB3J2E62jbLRGdiAFmc0EgskvYMVgdcZtq/k0us4OC4VAWletTowj+zgVCsTb2boWR0VLm1bkrutsICfocVeVtChg3EljN3v9LGVbyQWiuZq9NNTMck93aDdpY4wVQ01fUqeptBCiRTjb/KdqkuxAEqvpd//kKu/giuYTsDrzGvY+3ksgRmJG5KJG6yxdvxopjDtZbf+gzkpXCDTqnN5Fi/9+O4bcsyfbAlR1sQN7TjJhKGkjBFLqZyXRi/Wvn7cFaPehw6hO3FPNnSbQJ9UxsdTVgc0fC0ogU8DbmPuG3MgQ1r+oX4gRvaZPGUamCSRVqy6eO6W1Tm3/EMrq9/laj1DLt7XiRd3LN8rx2wc0P4vlHuZz6uYHxFP3iHeY2WUXtFbiYZsbHvRxWQNq+h6qSGd2pKiHAVzy4v7Pbb/jAiPLmJ1zdNBVuuBoHx+34kAuG02ArLJQez/lf7g6QExZlckZE7PMA2VUDQ0ZOP3wsxKfRZETGRI0O/XgWqFS2MyjZHj3VVmIdXWHT2EdKyWdclnpjr9J/vPAORJzn8p2BlDf6kOd5LsEOoEXEtpl0MdJuxSS2RVszPNugWVQA4RVbZdA5ceaTCAckneVO7+uUGRKUnKchFax/4AZ1n/GQtFxHWMTE7eSPDsiqDSvcwKV0oULSil9NbVFuCLNeOcIeh2TiMBatErCdE9kBCoQO+PEVsACgWhFbXKDUzbxNo2ADc6VnrsBKNTCK73RsFd6fwBfz4dNt/GeiAAAAABJRU5ErkJggg==);
                }
            }
        }

        .Header__title {
            margin-bottom: 16px;

            .Title__text {
                display: inline-block;
                vertical-align: top;
                font-family: Roboto, sans-serif;
                font-weight: 700;
                font-size: 32px;
            }
        }
    }
}

.Profile__content {
    width: 100%;
    margin: auto;

    .Profile__body {
        vertical-align: top;

        .Box {
            vertical-align: top;
            margin-bottom: 24px;

            &_bottom-32 {
                margin-bottom: 32px;
            }

            &_end {
                margin-bottom: 0;
            }

            &_inline {
                display: inline-block;
            }

            &_left {
                margin-right: 42px;
            }

            .Box__label {
                margin-bottom: 16px;
                font-family: Roboto, sans-serif;
                font-weight: 700;
                font-size: 16px;
                color: #949ea5;
                cursor: default;

                &_inline-block {
                    margin-right: 42px;
                    margin-bottom: 0;
                    display: inline-block;
                }
            }

            .Box__text {
                //margin-bottom: 24px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                //font-size: 24px;
                font-size: 16px;
                color: #2A3E4C;

                &_disabled {
                    opacity: .2;
                }
            }

            .Box__switch {
                margin-bottom: 24px;
                height: 32px;

                .switch {
                    display: inline-block;
                    position: relative;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    -webkit-tap-highlight-color: transparent;
                    tap-highlight-color: transparent;
                }

                .switch__lever {
                    position: relative;
                    display: inline-block;
                    width: 46px;
                    height: 32px;
                    //background: #FF0B0B;
                    background: #dce1e4;
                    border-radius: 16px;
                    transition: all 0.40s cubic-bezier(.17,.67,.43,.98);
                }

                .switch__lever::after {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 24px;
                    height: 24px;
                    border-radius: 12px;
                    top: 4px;
                    left: 4px;
                    background: white;
                    transition: all 0.25s ease-out;
                }

                .switch__index {
                    vertical-align: top;
                    //font: 400 18px/32px 'Roboto', sans-serif;
                    font: 400 16px/32px 'Roboto', sans-serif;
                    margin-left: 16px;
                }

                .switch__index::after {
                    transition: .37s;
                }

                .switch__index::after {
                    color: #dee2e4;
                    //color: #FF0B0B;
                    //color: #2A3E4C;
                    //opacity: 0.2;
                    content: "Отключено";
                }

                .switch_active {}

                .switch_active .switch__lever {
                    background: #4CAF50;
                }

                .switch_active .switch__lever:after {
                    left: 18px;
                }

                .switch .switch__lever:after {
                    box-shadow: 0 7px 27px -9px rgba(0,0,0,.5);
                }

                .switch .switch__lever:hover {
                    background: #dee2e4;
                    //background: #FF0B0B;
                }

                .switch_active .switch__lever:hover {
                    background-color: #4CAF50;
                }

                .switch_active .switch__index::after {
                    color: #4CAF50;
                    content: "Включено";
                }
            }

            .Box__link {
                display: inline-block;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: #0066CC;
                cursor: pointer;
                opacity: 0.7;
                transition: ease 0.3s;

                &:hover {
                    opacity: 1;
                }

                &_big {
                    font-size: 24px;
                }
            }

            .Box__title {
                font-family: Roboto, sans-serif;
                font-weight: 700;
                font-size: 20px;
                color: #2A3E4C;
            }
        }

        .Content__hr {
            width: 100%;
            height: 2px;
            border-radius: 2px;
            background-color: #f7f7f7;
            //margin-top: 24px;
            margin-bottom: 24px;

            &_bottom-32 {
                margin-bottom: 32px;
            }
        }

        .Form__field {
            position: relative;
            margin-top: 8px;
            //margin-bottom: 23px;

            .Field__label {
                //margin-bottom: 6px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                font-size: 14px;
                //color: #959799;
                color: #2a3e4c;
                padding: 0 5px;
                cursor: default;
                background-color: white;
                //background-color: #f1f1f1;
                border-radius: 4px;
                position: absolute;
                left: 12px;
                top: -8px;
            }

            .Field__input {
                //margin-bottom: 19px;
                width: 424px;
                padding: 15px 16px 14px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                font-size: 16px;
                background-color: white;
                //background-color: #f1f1f1;
                border-radius: 5px;
                //border-radius: 6px;
                //border: solid 2px #EFEFEF;
                border: solid 2px #e8e8e8;
                resize: vertical;
                outline: none;
                cursor: text;

                &::placeholder {
                    padding-top: 27px;
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    color: #dbdbdb;
                }

                &_password {
                    font: 900 26px Roboto !important;
                    letter-spacing: 3px !important;
                    padding: 9px 16px 7px !important;
                    color: #3e3e3e !important;
                }

                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0 52px #ffffff inset; /* можно изменить на любой вариант цвета */
                    -webkit-text-fill-color: #202020;
                }
            }

            .Field__error {
                pointer-events: none;
                width: 256px - 18px - 18px;
                padding: 0 18px;

                .Error__text {
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    color: #ff5722;
                    display: inline-block;
                }
            }

            .Field__button {
                //height: 52px;
                //width: 100%;
                background-color: #0061FF;
                //background-color: #202020;
                border-radius: 5px;
                //color: white;
                color: #f1f1f1;
                display: inline-block;
                font: 900 16px Roboto;
                //border: none;
                padding: 12px 24px 11px;
                outline: none;
                cursor: pointer;
                transition: ease 0.2s;
                letter-spacing: 0.05em;
                //opacity: 0.9;
                //border: solid 3px #181818;
                border: none;
                opacity: 1;

                &:hover {
                    //opacity: 1;
                    opacity: 0.9;
                }

                &:focus {
                    //opacity: 1;
                    opacity: 0.9;
                }

                &_disabled {
                    opacity: 1 !important;
                }
            }

            .Field__link {
                font-family: Roboto, sans-serif;
                font-weight: 400;
                font-size: 16px;
                text-decoration: none;
                color: #0061FF;
                cursor: pointer;
                opacity: 0.7;
                transition: ease 0.3s;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.Box__title {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #2A3E4C;
}

.Box__switch {
    margin-bottom: 24px;
    height: 32px;

    .switch {
        display: inline-block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        tap-highlight-color: transparent;
    }

    .switch__lever {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 24px;
        //background: #FF0B0B;
        background: #dce1e4;
        border-radius: 16px;
        transition: all 0.40s cubic-bezier(.17,.67,.43,.98);
    }

    .switch__lever::after {
        position: absolute;
        display: block;
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 12px;
        top: 5px;
        left: 5px;
        background: white;
        transition: all 0.25s ease-out;
    }

    .switch__index {
        vertical-align: top;
        //font: 400 18px/32px 'Roboto', sans-serif;
        font: 400 16px/27px 'Roboto', sans-serif;
        margin-left: 16px;
    }

    .switch__index::after {
        transition: .37s;
    }

    .switch__index::after {
        color: #dee2e4;
        //color: #FF0B0B;
        //color: #2A3E4C;
        //opacity: 0.2;
        content: "Отключено";
    }

    .switch_active {}

    .switch_active .switch__lever {
        background: #4CAF50;
    }

    .switch_active .switch__lever:after {
        left: 13px;
    }

    .switch .switch__lever:after {
        box-shadow: 0 7px 27px -9px rgba(0,0,0,.5);
    }

    .switch .switch__lever:hover {
        background: #dee2e4;
        //background: #FF0B0B;
    }

    .switch_active .switch__lever:hover {
        background-color: #4CAF50;
    }

    .switch_active .switch__index::after {
        color: #4CAF50;
        content: "Включено";
    }
}