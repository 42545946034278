.Curator {

    .Curator__body {

        .Body__title {
            color: #2a3e4c;
            font-family: Roboto, sans-serif;
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 24px;
        }

        .Body__list {
            width: 100%;
            margin-bottom: 42px;

            .List__item {
                transition: ease .25s;
                color: #2a3e4c;
                font-family: Roboto, sans-serif;
                font-size: 19px;
                font-weight: 700;
                background-color: #EEEEEE;
                border-radius: 7px;
                //padding: 17px 24px 16px 24px;
                padding: 12px 24px 7px 24px;
                cursor: pointer;
                margin-bottom: 12px;

                &:hover {
                    background-color: #ffffff;
                }

                &.active {
                    background-color: #ffffff;
                    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.07);
                }
            }

            .Item__hr {
                margin: 0 16px;

                &_margin-32 {
                    margin: 0 32px;
                }

                &_vertical {
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUOE+F1O1LwkAcwPG7zamBhWmERIhIREiEoHPm9sK3lZp/avgQ1CtfOJ9HEiHhCxEJk5AYZaZuu4teFLXtznv9efH7cd87CNYcQcrHDKQtO86PR0izoVDaHdj3JjHW9aZcqlIxz58HWJfzSEfatFMrP1CxIFxGIAd3lxj17+TCmI7FjAShwzF5UhvDYWVBxJFI2rPl88YxNhZNudj43o2IBeFsH3KuA80wJkq9+LgG504gx/jmaN67r92+0DDDp3ISwBi26+8yABWdiKPRvNftAVGE0awlFzo/d2E7My9dhFnABQ2gjdrV8oCKE2IuzkDGs5iBbrd7pVJw2sGfbooAQtyuFaoAAETEsVh2h9tgjw0E1Hbtqvu3HcvMicT5IeN07mlYHyhyaUTFgphNQsi6317VTq9XmRGxOUlzvv/GMCdJxeYk6diUJBHbJUnEMTET5KAjjFarcat13bd7m78L8ql8lGWAV/s0HhSlOKVh2yRtxyAlaYtJSdpiUpI2mJykBdOStGBakhZMS9KCv7P0+13binLzvO77/QK1APGlKBSmpwAAAABJRU5ErkJggg==);
                    background-repeat: no-repeat;
                    height: 32px;
                    width: 11px;
                }

                &_inline {
                    display: inline-block;
                }
            }

            .Button__add {
                margin: 16px 24px 24px 13px;
                display: inline-block;
                cursor: pointer;
                height: 42px;

                .Add__text {
                    //margin-top: 8px;
                    margin-left: 12px;
                    vertical-align: top;
                    display: inline-block;

                    //font-size: 15px;
                    //font-weight: 700;
                    //background-color: #2A3E4C;
                    //color: white;
                    //padding: 4px 6px 3px;
                    //border-radius: 4px;

                    margin-top: 10px;
                    font-family: Roboto, sans-serif;
                    //font-weight: 400;
                    //color: #0066cc;
                    font-size: 19px;
                    //color: #2A3E4C;
                    font-weight: 500;
                    //opacity: .25;

                    color: #0061ff;
                    opacity: .75;
                }
            }

            .Button__edit {
                margin: 16px 24px 24px 0;
                display: inline-block;
                opacity: 0.5;
                cursor: pointer;
                transition: ease .25s;
                height: 42px;

                &:hover {
                    opacity: 1;
                }
            }

            .Button__send {
                margin: 16px 24px 24px 0;
                display: inline-block;
                opacity: 0.5;
                cursor: pointer;
                transition: ease .25s;
                height: 42px;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .Body__table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0 0;
            margin: 24px 0 0;

            .Table__tr {
                cursor: pointer;
                transition: ease .25s;

                &:hover {
                    box-shadow: none;

                    .Table__td {
                        background-color: #EEEEEE;
                    }
                }

                .Table__td {
                    color: #373b50;
                    font-family: Roboto, sans-serif;
                    font-size: 17px;
                    font-weight: 400;
                    height: 56px;
                    padding: 0 24px;

                    transition: ease .25s;

                    &:first-child {
                        padding: 0 16px;
                        width: 36px;
                        border-top-left-radius: 7px;
                        border-bottom-left-radius: 7px;
                    }

                    &:nth-child(2) {
                        width: 30%;
                        padding: 0 24px 0 0;
                    }

                    &:nth-child(3) {
                        width: 30%;
                        padding: 0 24px 0 0;
                    }

                    &:last-child {
                        padding: 0 24px;
                        width: 16px;
                        border-bottom-right-radius: 7px;
                        border-top-right-radius: 7px;
                    }
                }
            }
        }
    }
}

.Student__photo {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiI+DQogIDxpbWFnZSBpZD0iYmFzZWxpbmVfYWNjb3VudF9jaXJjbGVfYmxhY2tfMzZkcCIgd2lkdGg9IjM2IiBoZWlnaHQ9IjM2IiBvcGFjaXR5PSIwLjEiIHhsaW5rOmhyZWY9ImRhdGE6aW1hZ2UvcG5nO2Jhc2U2NCxpVkJPUncwS0dnb0FBQUFOU1VoRVVnQUFBQ1FBQUFBa0NBWUFBQURoQUppWUFBQUJ0VWxFUVZSNEFlM1dBV1FDVVJqQThTR0VFSVpER0laRENNTVF4akFNSVVBSUF3d1FCZ2hoT0J4Z2dCQ0d3MkdBQUNHRU1Bd2hIQTRoaEJDKy9SSG9ydDUzN3k1QmYzNkF6dXU5ZCsvZHpabTYxb1NIRUZNczl5WUlNRUFEWiswV1BsWVFwUWg5VkZCWUpmU3hobGlLOFk3Y09aaENDdktUWjdaY1JKQ0MvY0t4bVprWW9yQkFnQkNSZmxENm1TcGpCakhZb0p1eTMzcllxWlpQbVFkUmFPTllQWWlaZWFQWHNJVVlURTF2cG5MNVl0UFNEU0VLUGt5RkVJVStVcXRnQzFId1lPcGJmM2ltMTRJb2pXRnFBVkZxSU5FSWtzRVRqdFdGWkRCQW9wbkZkWkEycURZMmtBd0NKSW9nRnNidzRPZTRaaVpJSkJlMHZPQ0E5RzlhRExtUUtSTE5NNjc1SjFxbzQyNnZnVGI4ak04TGtTaUFuTENEajN0b2EyQm9lOUIyREsvNEEyeDdNWHdCTkpHb2V1SkhyOGpiRnlURnl1WkNyQ052enpZWHRYdGtsdjdnd0RZSFM4aUJOVzVCMlQ5QjVxZ2hhM1VzOUo4ZXlhb25IckRHRzBvd1ZjWUh0aWZPbmhKVXVZWUxjb2tCSGxFNStETlA4QTBIYldTekJacUdRZG1LNGNJcUZ3dElRV1p3a0tzcWh0aEJMRzNob1l6Q2NoRmlsM0VnUTlSd3Rxcm9JTUFjOGNGbW5XR0VGaW9vdEd2L2d6eHFIOFdKaFRvQUFBQUFTVVZPUks1Q1lJST0iLz4NCjwvc3ZnPg0K");
    background-repeat: no-repeat;
    height: 36px;
    width: 36px;
}

.Student__online {
    height: 16px;
    width: 16px;
    background-color: #B3B3B3;
    border-radius: 50%;

    &_green {
        background-color: #4CAF50;
    }
}
