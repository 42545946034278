@charset "utf-8";

html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  background-color: #f7f7f7;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  overflow-x: auto;
  overflow-y: auto;
}

body.overflow_hidden {
  overflow-y: hidden !important;
}

li {
  list-style-type: none; /* Убираем маркеры */
}

ul {
  margin: 0; /* Отступ слева в браузере IE и Opera */
  padding: 0; /* Отступ слева в браузере Firefox, Safari, Chrome */
  list-style: none;
}

a {
  text-decoration: none; /* Отменяем подчеркивание у ссылки */
}

input:focus {
  outline: none;
}

button:focus {
  outline: none;
}

/*Для Google charts gantt
text {
  fill: #2A3E4C !important;
}
 */
