.Page__loader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 42px;
    height: calc(100% - 84px);
    overflow: visible;

    .Body_nodata {
        width: 100%;
        opacity: .05;
        font-family: 'Roboto', sans-serif;
        font-size: 85px;
        font-weight: 700;
        text-align: center;
        color: #2A3E4C;
    }

    .Header__path {
        pointer-events: auto;
        margin-bottom: 20px;

        .Path__link {
            display: inline-block;
            font-family: Roboto, sans-serif;
            font-weight: 700;
            font-size: 24px;
            color: #0061FF;
            transition: ease 0.3s;
            cursor: pointer;

            &_text {
                color: #2A3E4C;
                opacity: .2;

                &:hover {
                    opacity: .4;
                }
            }

            &_blue {
                color: #0061FF;
                opacity: .7;

                &:hover {
                    opacity: 1;
                }
            }

            &_disabled {
                cursor: default;

                &:hover {
                    opacity: .2;
                }
            }
        }
    }
}

.Page__section {
    position: relative;
    padding: 42px;
    height: calc(100% - 42px);
    margin: auto;

    .Section__header {
        margin-bottom: 42px;

        &_margin-bottom-16 {
            margin-bottom: 16px;
        }

        &_margin-bottom-38 {
            margin-bottom: 38px;
        }
    }

    .Section__content {
        height: 100%;
    }

    .Section__button {
        position: fixed;
        bottom: 36px;
        right: 42px;
        border-radius: 50%;

        .button {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MiIgaGVpZ2h0PSI3MiIgdmlld0JveD0iMCAwIDcyIDcyIj4NCiAgPGcgaWQ9Ikdyb3VwXzE2NjgiIGRhdGEtbmFtZT0iR3JvdXAgMTY2OCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4MDYgLTk2NikiPg0KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfNDIiIGRhdGEtbmFtZT0iRWxsaXBzZSA0MiIgY3g9IjM2IiBjeT0iMzYiIHI9IjM2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxODA2IDk2NikiIGZpbGw9IiMwMDYxZmYiLz4NCiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzE1OTAiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDE1OTAiIHdpZHRoPSI2IiBoZWlnaHQ9IjIyIiByeD0iMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTgzOSA5OTEpIiBmaWxsPSIjZmZmIi8+DQogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8xNTkxIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxNTkxIiB3aWR0aD0iNiIgaGVpZ2h0PSIyMiIgcng9IjEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE4NTMgOTk5KSByb3RhdGUoOTApIiBmaWxsPSIjZmZmIi8+DQogIDwvZz4NCjwvc3ZnPg0K");
            background-repeat: no-repeat;
            border-radius: 50%;
            height: 72px;
            width: 72px;
            cursor: pointer;
            transition: ease .25s;

            &_save {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MiIgaGVpZ2h0PSI3MiIgdmlld0JveD0iMCAwIDcyIDcyIj4NCiAgPGcgaWQ9Ikdyb3VwXzE1NDAiIGRhdGEtbmFtZT0iR3JvdXAgMTU0MCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4MDYgLTk2NikiPg0KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfNDIiIGRhdGEtbmFtZT0iRWxsaXBzZSA0MiIgY3g9IjM2IiBjeT0iMzYiIHI9IjM2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxODA2IDk2NikiIGZpbGw9IiMwMDYxZmYiLz4NCiAgICA8ZyBpZD0iR3JvdXBfMTU2NSIgZGF0YS1uYW1lPSJHcm91cCAxNTY1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjM4LjQyNCAtMTAwNi4wNjIpIHJvdGF0ZSg0NSkiPg0KICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8xNTkwIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxNTkwIiB3aWR0aD0iNiIgaGVpZ2h0PSIyMCIgcng9IjEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE4NDggOTgxKSIgZmlsbD0iI2ZmZiIvPg0KICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8xNTkxIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxNTkxIiB3aWR0aD0iNiIgaGVpZ2h0PSIxNiIgcng9IjEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE4NTQgOTk1KSByb3RhdGUoOTApIiBmaWxsPSIjZmZmIi8+DQogICAgPC9nPg0KICA8L2c+DQo8L3N2Zz4NCg==");
            }

            &:hover {
                box-shadow: 0 16px 20px 0 rgba(0, 0, 0, 0.14);
            }

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.Header__title {
    color: #2A3E4C;
    cursor: default;

    .Title__text {
        display: inline-block;
        vertical-align: top;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        font-size: 32px;
    }

    .Button__edit {
        height: 42px;
        margin-left: 24px;
        display: inline-block;
        opacity: 0.5;
        cursor: pointer;
        transition: ease .25s;

        &:hover {
            opacity: 1;
        }
    }
}
