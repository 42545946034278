.Employees {

    .Employees__body {

        .Body__title {
            color: #2a3e4c;
            font-family: Roboto, sans-serif;
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 24px;
        }

        .Body__table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0 0;
            margin-bottom: 42px;

            .Table__tr {
                cursor: pointer;
                transition: ease .25s;

                &:hover {
                    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.07);

                    .Table__td {
                        background-color: #ffffff;
                    }
                }

                .Table__td {
                    color: #373b50;
                    font-family: Roboto, sans-serif;
                    font-size: 17px;
                    font-weight: 400;
                    height: 56px;
                    padding: 0 24px;

                    transition: ease .25s;

                    &:first-child {
                        padding: 0 16px;
                        width: 36px;
                        border-top-left-radius: 7px;
                        border-bottom-left-radius: 7px;
                    }

                    &:nth-child(2) {
                        width: 30%;
                        padding: 0 24px 0 0;
                    }

                    &:nth-child(3) {
                        width: 30%;
                        padding: 0 24px 0 0;
                    }

                    &:last-child {
                        padding: 0 24px;
                        width: 16px;
                        border-bottom-right-radius: 7px;
                        border-top-right-radius: 7px;
                    }
                }
            }
        }
    }
}

.Employee__photo {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiI+DQogIDxpbWFnZSBpZD0iYmFzZWxpbmVfYWNjb3VudF9jaXJjbGVfYmxhY2tfMzZkcCIgd2lkdGg9IjM2IiBoZWlnaHQ9IjM2IiBvcGFjaXR5PSIwLjEiIHhsaW5rOmhyZWY9ImRhdGE6aW1hZ2UvcG5nO2Jhc2U2NCxpVkJPUncwS0dnb0FBQUFOU1VoRVVnQUFBQ1FBQUFBa0NBWUFBQURoQUppWUFBQUJ0VWxFUVZSNEFlM1dBV1FDVVJqQThTR0VFSVpER0laRENNTVF4akFNSVVBSUF3d1FCZ2hoT0J4Z2dCQ0d3MkdBQUNHRU1Bd2hIQTRoaEJDKy9SSG9ydDUzN3k1QmYzNkF6dXU5ZCsvZHpabTYxb1NIRUZNczl5WUlNRUFEWiswV1BsWVFwUWg5VkZCWUpmU3hobGlLOFk3Y09aaENDdktUWjdaY1JKQ0MvY0t4bVprWW9yQkFnQkNSZmxENm1TcGpCakhZb0p1eTMzcllxWlpQbVFkUmFPTllQWWlaZWFQWHNJVVlURTF2cG5MNVl0UFNEU0VLUGt5RkVJVStVcXRnQzFId1lPcGJmM2ltMTRJb2pXRnFBVkZxSU5FSWtzRVRqdFdGWkRCQW9wbkZkWkEycURZMmtBd0NKSW9nRnNidzRPZTRaaVpJSkJlMHZPQ0E5RzlhRExtUUtSTE5NNjc1SjFxbzQyNnZnVGI4ak04TGtTaUFuTENEajN0b2EyQm9lOUIyREsvNEEyeDdNWHdCTkpHb2V1SkhyOGpiRnlURnl1WkNyQ052enpZWHRYdGtsdjdnd0RZSFM4aUJOVzVCMlQ5QjVxZ2hhM1VzOUo4ZXlhb25IckRHRzBvd1ZjWUh0aWZPbmhKVXVZWUxjb2tCSGxFNStETlA4QTBIYldTekJacUdRZG1LNGNJcUZ3dElRV1p3a0tzcWh0aEJMRzNob1l6Q2NoRmlsM0VnUTlSd3Rxcm9JTUFjOGNGbW5XR0VGaW9vdEd2L2d6eHFIOFdKaFRvQUFBQUFTVVZPUks1Q1lJST0iLz4NCjwvc3ZnPg0K");
    background-repeat: no-repeat;
    height: 36px;
    width: 36px;
}

.Employee__online {
    height: 16px;
    width: 16px;
    background-color: #B3B3B3;
    border-radius: 50%;

    &_green {
        background-color: #4CAF50;
    }
}
