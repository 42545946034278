.Form {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    width: 460px;

    &_null {
        width: 21px;
        height: 21px;
    }

    &_width-796 {
        width: 796px;
    }

    &_width-690 {
        width: 690px;
    }

    .Form__header {
        margin-bottom: 32px;

        .Header__title {
            font-family: Roboto, sans-serif;
            font-weight: 700;
            font-size: 32px;
            color: #2A3E4C;
        }
    }

    .Form__content {
        //margin-bottom: 22px;

        .Content__box {
            position: relative;
            vertical-align: top;
            margin-bottom: 32px;

            .Form__field {
                position: relative;
                margin-top: 8px;
                //margin-bottom: 23px;

                .Field__label {
                    //margin-bottom: 6px;
                    font-family: Roboto, sans-serif;
                    font-weight: 700;
                    font-size: 14px;
                    //color: #959799;
                    color: #949ea5;
                    padding: 0 8px;
                    cursor: default;
                    background-color: white;
                    //background-color: #f1f1f1;
                    border-radius: 4px;
                    position: absolute;
                    left: 9px;
                    top: -8px;
                }

                .Field__select {
                    //margin-bottom: 19px;
                    width: 460px;
                    padding: 17px 16px 16px;
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    background-color: white;
                    //background-color: #f1f1f1;
                    border-radius: 7px;
                    //border-radius: 6px;
                    //border: solid 2px #EFEFEF;
                    border: solid 2px #e8e8e8;
                    resize: vertical;
                    outline: none;
                    cursor: pointer;
                    transition: ease background .25s;

                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC4xNDMiIGhlaWdodD0iOS45IiB2aWV3Qm94PSIwIDAgMTQuMTQzIDkuOSI+DQogIDxnIGlkPSJHcm91cF8xNjcwIiBkYXRhLW5hbWU9Ikdyb3VwIDE2NzAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNDYwLjkyOSAtNTM2LjE3MSkiPg0KICAgIDxnIGlkPSJHcm91cF85MjAiIGRhdGEtbmFtZT0iR3JvdXAgOTIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0MjAuNTYzIDExMDUuNjAyKSByb3RhdGUoLTQ1KSI+DQogICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzExODAiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDExODAiIHdpZHRoPSIxMCIgaGVpZ2h0PSI0IiByeD0iMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEzNi4yOTMgMzQxLjAwMSkiIGZpbGw9IiM5NTlmYTYiLz4NCiAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMTE4MSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMTE4MSIgd2lkdGg9IjQiIGhlaWdodD0iMTAiIHJ4PSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMTM2LjI5MyAzMzUpIiBmaWxsPSIjOTU5ZmE2Ii8+DQogICAgPC9nPg0KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMjA3OSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMjA3OSIgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHJ4PSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNDYyIDUzNykiIGZpbGw9IiM5NTlmYTYiLz4NCiAgPC9nPg0KPC9zdmc+DQo=");
                    background-repeat: no-repeat;
                    background-position: right 16px center;

                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none !important;

                    .option__hr {
                        width: 100%;
                        font-size: 4px;
                    }

                    .option__info {
                        font: 400 12px Roboto;
                    }
                }

                .Field__input {
                    //margin-bottom: 19px;
                    width: 424px;
                    padding: 17px 16px 16px;
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    background-color: white;
                    //background-color: #f1f1f1;
                    border-radius: 7px;
                    //border-radius: 6px;
                    //border: solid 2px #EFEFEF;
                    border: solid 2px #e8e8e8;
                    resize: vertical;
                    outline: none;
                    cursor: text;

                    &::placeholder {
                        padding-top: 27px;
                        font-family: Roboto, sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        color: #dbdbdb;
                    }

                    &_password {
                        font: 900 26px Roboto !important;
                        letter-spacing: 3px !important;
                        padding: 11px 16px 9px !important;
                        color: #3e3e3e !important;
                    }

                    &:-webkit-autofill {
                        -webkit-box-shadow: 0 0 0 52px #ffffff inset; /* можно изменить на любой вариант цвета */
                        -webkit-text-fill-color: #202020;
                    }


                }



                .Field__error {
                    pointer-events: none;
                    width: 256px - 18px - 18px;
                    padding: 0 18px;

                    .Error__text {
                        font-family: Roboto, sans-serif;
                        font-weight: 400;
                        font-size: 14px;
                        color: #ff5722;
                        display: inline-block;
                    }
                }

                .Field__button {
                    //height: 52px;
                    //width: 100%;
                    background-color: #0061FF;
                    //background-color: #202020;
                    border-radius: 5px;
                    //color: white;
                    color: #f1f1f1;
                    display: inline-block;
                    font: 900 16px Roboto;
                    //border: none;
                    padding: 12px 24px 11px;
                    outline: none;
                    cursor: pointer;
                    transition: ease 0.2s;
                    letter-spacing: 0.05em;
                    //opacity: 0.9;
                    //border: solid 3px #181818;
                    border: none;
                    opacity: 1;

                    &:hover {
                        //opacity: 1;
                        opacity: 0.9;
                    }

                    &:focus {
                        //opacity: 1;
                        opacity: 0.9;
                    }

                    &_disabled {
                        opacity: 1 !important;
                    }
                }

                .Field__link {
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    text-decoration: none;
                    color: #0061FF;
                    cursor: pointer;
                    opacity: 0.7;
                    transition: ease 0.3s;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            .Navigate__wrapper {
                text-align: center;
                margin: 0 0 24px;
                width: 448px;
                display: inline-block;
                padding: 6px 3px;
                border: 2px solid #f0f3f5;
                border-radius: 9px;

                &_top {
                    margin-top: 6px;
                }

                &_margin {
                    margin-right: 48px;
                }

                &_left {
                    float: left;
                    margin-left: 24px;
                }

                &_right {
                    float: right;
                    margin-right: 24px;
                }
            }

            .Navigate__link {
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 16px;
                margin: 0 3px 0 4px;
                transition: ease 0.3s;
                cursor: pointer;
                padding: 6px 29px;
                border-radius: 5px;
                color: #2A3E4C;
                opacity: .3;

                &.active {
                    //opacity: 0.9;
                    opacity: 1;
                    background-color: #f0f3f5;
                    cursor: default;

                    &:hover {
                        //opacity: 0.9;
                        opacity: 1;
                        background-color: #f0f3f5;
                    }
                }

                &:hover {
                    opacity: 0.5;
                }
            }

            .Label__question {
                position: absolute;
                top: 3px;
                right: 6px;
                display: inline-block;
                float: right;
                height: 18px;
                width: 17px;
                background-color: #2a3e4c;
                opacity: 0.25;
                transition: ease 0.3s;
                cursor: pointer;
                border-radius: 50%;
                padding-left: 1px;

                &.icon-question {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 900;
                    font-size: 16px;
                    color: #fff;
                    text-align: center;
                }

                &:hover {
                    opacity: 1;
                }
            }

            .Box__label {
                //width: 450px;
                //width: 100%;
                width: calc(100% - 10px);
                margin-bottom: 6px;
                font: 400 14px Roboto;
                color: #7B898D;
                background-color: white;
                border-radius: 4px;
                display: inline-block;
                padding: 0 0 0 5px;
                transition: .37s;
                cursor: default;

                &_link {
                    float: right;
                    text-decoration: none;
                    color: #0066cc;
                    cursor: pointer;
                    opacity: 0.7;
                    transition: ease 0.3s;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            .Box__info {
                font: 400 16px Roboto;
                margin: 20px 0;
                color: #2A3E4C;
            }

            .Box__text {
                font-size: 18px;
                color: #2A3E4C;
                font-weight: 400;
                font-family: Roboto, sans-serif;
                margin-bottom: 20px;

                &_disabled {
                    opacity: .25;
                }

                &_center {
                    text-align: center;
                }
            }

            .Box__link {
                //width: 450px;
                //width: 100%;
                padding: 0 19px 0 5px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                font-size: 16px;
                text-decoration: none;
                color: #0066cc;
                cursor: pointer;
                opacity: 0.7;
                transition: ease 0.3s;
                display: inline-block;

                &:hover {
                    opacity: 1;
                }
            }

            .Box__input {
                //width: 424px;
                width: calc(100% - 36px);
                //margin-bottom: 20px;
                //padding: 17px 16px 16px;
                padding: 15px 16px 14px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                font-size: 16px;
                background: #fff;
                border-radius: 5px;
                border: solid 2px #f0f3f5;
                resize: vertical;
                outline: none;
                cursor: text;
                transition: .37s;

                &_password {
                    font: 900 26px Roboto !important;
                    letter-spacing: 3px !important;
                    padding: 11px 16px 9px !important;
                    color: #3e3e3e !important;
                }

                &_margin-none {
                    margin: 0;
                }
            }

            .Box__candy {
                display: inline-block;
                margin: 0 16px 12px 0;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 16px;
                border-radius: 5px;
                padding: 7px 10px 6px;
                color: #2A3E4C;
                background-color: #f0f3f5;

                //.Label__text {
                //    display: inline-block;
                //    opacity: 0.7;
                //}

                .Label__clear {
                    margin-left: 10px;
                    display: inline-block;
                    float: right;
                    height: 18px;
                    width: 18px;
                    background-color: #cacfd3;
                    opacity: 1;
                    transition: ease 0.3s;
                    cursor: pointer;
                    border-radius: 50%;

                    &.icon-clear {
                        font-family: 'Roboto', sans-serif;
                        font-weight: 700;
                        font-size: 15px;
                        color: #f0f3f5;
                        text-align: center;
                        -moz-transform: rotate(45deg); /* Для Firefox */
                        -ms-transform: rotate(45deg); /* Для IE */
                        -webkit-transform: rotate(45deg); /* Для Safari, Chrome, iOS */
                        -o-transform: rotate(45deg); /* Для Opera */
                        transform: rotate(45deg);
                    }

                    &:hover {
                        background-color: #959fa6;
                    }
                }
            }

            .Box__file {
                //width: 424px;
                width: calc(100% - 36px);
                margin-bottom: 20px;
                padding: 17px 16px 16px;
                font: 400 16px Roboto;
                background: #fff;
                border-radius: 0;
                border: solid 2px #f0f3f5;
                resize: vertical;
                outline: none;
                cursor: pointer;
                transition: .37s;
            }

            .Box__select {
                position: relative;
                //width: 460px;
                width: 100%;
                margin-bottom: 20px;
                padding: 17px 12px 16px;
                font: 400 16px Roboto;
                background-color: #fff;
                border-radius: 0;
                border: solid 2px #f0f3f5;
                resize: vertical;
                outline: none;
                cursor: pointer;
                transition: .37s;

                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi45NzEiIGhlaWdodD0iMTIuMDIxIiB2aWV3Qm94PSIwIDAgMTYuOTcxIDEyLjAyMSI+ICA8ZyBpZD0iR3JvdXBfOTIwIiBkYXRhLW5hbWU9Ikdyb3VwIDkyMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwNDAuODYxIDU3MC42MzUpIHJvdGF0ZSgtNDUpIj4gICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8xMTgwIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxMTgwIiB3aWR0aD0iMTIiIGhlaWdodD0iNSIgcng9IjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExMzcgMzQyKSIgZmlsbD0iI2NhY2ZkMyIvPiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzExODEiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDExODEiIHdpZHRoPSI1IiBoZWlnaHQ9IjEyIiByeD0iMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEzNyAzMzUpIiBmaWxsPSIjY2FjZmQzIi8+ICA8L2c+PC9zdmc+);
                background-repeat: no-repeat;
                background-position: right 16px center;
                fill: black;

                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none !important;

                .option__hr {
                    width: 100%;
                    font-size: 4px;
                }

                .option__info {
                    font: 400 12px Roboto;
                }
            }
        }

        //.Content__hr {
        //    //width: 448px;
        //    width: calc(100% - 12px);
        //    margin: 12px auto 20px;
        //    height: 2px;
        //    background-color: #EFF2F5;
        //    border: none;
        //}

        .Content__hr {
            width: 100%;
            height: 2px;
            border-radius: 2px;
            background-color: #f7f7f7;
            //margin-top: 24px;
            margin-bottom: 24px;

            &_bottom-32 {
                margin-bottom: 32px;
            }
        }

        .Content__br {
            height: 24px;
        }

        .Content__error {
            margin-bottom: 32px;
            //width: 460px;
            opacity: 1;
            pointer-events: none;
            -webkit-transition: opacity .2s linear .1s;
            transition: opacity .2s linear .1s;
            //display: -webkit-box;
            //display: -ms-flexbox;
            //display: flex;

            .Error__text {
                font: 400 16px Roboto;
                color: #ff693a;
                //align-self: center;
                //display: inline-block;
                //max-width: 460px;
                //margin-left: 5px;
            }
        }
    }



    .Form__footer {
        //height: 52px;

        .Form__button {
            //height: 52px;
            //width: 100%;
            background-color: #0061FF;
            //background-color: #202020;
            border-radius: 5px;
            //color: white;
            color: #f1f1f1;
            display: inline-block;
            font: 900 16px Roboto;
            //border: none;
            padding: 12px 24px 11px;
            outline: none;
            cursor: pointer;
            transition: ease 0.2s;
            letter-spacing: 0.05em;
            //opacity: 0.9;
            //border: solid 3px #181818;
            border: none;
            opacity: 1;

            &_cancel {
                background-color: white;
                width: 188px;

                &:hover {
                    opacity: 0.6 !important;
                    color: #606f79;
                }
            }

            &_disabled {
                opacity: 1 !important;
            }

            &_left {
                margin-right: 42px;
            }

            &_blue {
                background-color: #0066CC;
                color: white;
                font-weight: 700;
                opacity: 0.9;
            }

            &_red {
                background-color: #F44336;
                color: white;
                font-weight: 700;
                opacity: 0.9;
            }

            &:hover {
                opacity: 1;
            }

            &:focus {
                opacity: 1;
            }
        }
    }
}

.Form__link {
    margin-top: 42px;

    .Link__delete {
        text-align: center;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 16px;
        text-decoration: none;
        color: #FF4C55;
        cursor: pointer;
        opacity: 0.7;
        transition: ease 0.3s;

        &:hover {
            opacity: 1;
        }
    }
}

.Field__link {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
    color: #0061FF;
    cursor: pointer;
    opacity: 0.7;
    transition: ease 0.3s;

    &:hover {
        opacity: 1;
    }
}


.Box {

    .Box__menu {
        margin-top: 42px;

        .Menu__button {
            position: relative;
            display: inline-block;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 700;
            color: #2A3E4C;
            opacity: .5;
            padding-bottom: 12px;
            cursor: pointer;
            margin-right: 36px;
            transition: ease .25s;

            .Button__border {
                position: absolute;
                bottom: 0;
                width: 0;
                height: 4px;
                border-radius: 1px;
                background-color: #ffffff;
                transition: ease .25s;
            }

            &_right {
                margin-right: 0;
            }

            &:hover {
                opacity: 1;
            }

            &.active {
                cursor: default;
                opacity: 1;
                color: #2A3E4C;

                .Button__border {
                    width: 100%;
                    background-color: #0b7aff;
                }
            }
        }
    }

    .Menu__hr {
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: #f7f7f7;
        //margin-bottom: 32px;
    }
}

.Box__avatar {
    position: relative;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABHNCSVQICAgIfAhkiAAAAotJREFUaEPtmety1DAMRr/lVqBAO1Cg5f2fjVvLnXIttHN24ozxpLHkVWYTZjWzf1LH+Y4lS7K70sJttXD9GgN4UcC9nCPsDmDbXtl5YOeBDVeAECqzjXfKlJ1a53kv6Yf3o2n8HADQ8k7SzxaIuQBcdhC/vBBzAUD3X0lnki48EJFptHUPWPX+loSHPkvCY2tbEkDS/EfS2wTRAjD1Sls8ci7pU6sH5gBAOJ0uGQDt6/qz1BAyAVwXi9YQojB9l4S7SZHYTUm3Jd2TdMcS7CNjqh5oBaAtINXV8jkgBxuAVAGsK52DIvyrc2WB2He+YwohL8B14m91oUMYEU5D1gIR6gFi/UOhbK8LEQByo5p+HAixI2c4hQK8kUSFTPZQEr8xA+JbNoA98dQRSmEAiEBMMrILq2kxmre8A/V4IQygPJA872LeAoB4IJKxmdkPFgsDeF3keQA8loefx3thAPmF111Jjz3qi9MYhc66AJMA3Jd06ATI98ENScfG98MA8hAidT4xCkjDXmUHFE8mCgMoD+QnlSYx5+vb4u6hx4NhAP3hohPhyST09Hl1xnt40WJhAJxPySSp4+Tj7ANWc8y+SOKXjIr9zKK8GxMGwHylF3hGRgKEjZkbFZu+ifYjN7IX71gtFICP0guVonjOyrI5OTzRYg/d/TyQ9MiqfAoPMCehREsxBDGmrUU884V7IIkknIjtfE8MAVC0WHVOZy02GUDyBk0ep7P8SMl+oF0g1mubvAY1KUDt4xF///8BPBU1YkU9c5DNuF4cvRfyVEXPxyPG9oeosYstT2MVIco6xz+Vv/afeiBIdWSO2lirgNZxCOeyjEvd/vy9bVGtMP17O4CNl3DDCRbvgSuvHJUxaftS1QAAAABJRU5ErkJggg==);
    background-position: center center;

    &.Avatar__image {
        background-image: none;
        border-radius: 50%;
    }

    .Avatar__image {
        background-image: none;
        border-radius: 50%;
    }
}

.Box__clear {
    position: absolute;
    top: -6px;
    right: -6px;
    display: inline-block;
    float: right;
    height: 36px;
    width: 36px;
    opacity: 1;
    cursor: pointer;
    border-radius: 50%;

    &.icon-clear {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4NCiAgPGcgaWQ9Ikdyb3VwXzE2NTQiIGRhdGEtbmFtZT0iR3JvdXAgMTY1NCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM5My4wMDEgLTE0NSkiPg0KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfNDMiIGRhdGEtbmFtZT0iRWxsaXBzZSA0MyIgY3g9IjE4IiBjeT0iMTgiIHI9IjE4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzOTMuMDAxIDE0NSkiIGZpbGw9IiNmZmYiLz4NCiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzQ0IiBkYXRhLW5hbWU9IkVsbGlwc2UgNDQiIGN4PSIxMiIgY3k9IjEyIiByPSIxMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzk5LjAwMSAxNTEpIiBmaWxsPSIjZjQ0MzM2Ii8+DQogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8xNjEzIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxNjEzIiB3aWR0aD0iMTAiIGhlaWdodD0iNCIgcng9IjEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwNiAxNjEpIiBmaWxsPSIjZmZmIi8+DQogIDwvZz4NCjwvc3ZnPg0K");
        height: 36px;
        width: 36px;
        background-repeat: no-repeat;
    }
}

.Box__add {
    position: absolute;
    top: -6px;
    right: -6px;
    display: inline-block;
    float: right;
    height: 36px;
    width: 36px;
    opacity: 1;
    cursor: pointer;
    border-radius: 50%;

    &.icon-add {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4NCiAgPGcgaWQ9Ikdyb3VwXzE2NjkiIGRhdGEtbmFtZT0iR3JvdXAgMTY2OSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwMzcuMDAxIC0yMzQpIj4NCiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzQzIiBkYXRhLW5hbWU9IkVsbGlwc2UgNDMiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAzNy4wMDEgMjM0KSIgZmlsbD0iI2ZmZiIvPg0KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMjA3NyIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMjA3NyIgd2lkdGg9IjYiIGhlaWdodD0iMTgiIHJ4PSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDUyIDI0MykiIGZpbGw9IiNkNmQ2ZDYiLz4NCiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzIwNzgiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDIwNzgiIHdpZHRoPSI2IiBoZWlnaHQ9IjE4IiByeD0iMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTA2NCAyNDkpIHJvdGF0ZSg5MCkiIGZpbGw9IiNkNmQ2ZDYiLz4NCiAgPC9nPg0KPC9zdmc+DQo=");
        height: 36px;
        width: 36px;
        background-repeat: no-repeat;
    }
}


.Form__section {
    .Body__list {
        .List__item {
            transition: ease .25s;
            &_active {
                &:hover {
                    background-color: #eeeeee !important;
                }
            }
        }
    }
}

