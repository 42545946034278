.ProfileModal {
    position: fixed; /* фиксированное положение */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(240, 243, 245, 0.96); /* цвет фона */
    background-color: rgba(32, 32, 32, 0.29); /* цвет фона */
    z-index: 999;
    -webkit-transition: opacity 200ms ease-in;
    -moz-transition: opacity 200ms ease-in;
    transition: opacity 200ms ease-in; /* анимация перехода */
    margin: 0;
    padding: 0;
    opacity: 1; /* делаем окно видимым */
    pointer-events: auto; /* элемент видим для событий мыши */
    overflow-y: scroll; /* добавляем прокрутку по y, когда элемент не помещается на страницу */

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    /* ширина модального окна и его отступы от экрана */
    .ProfileModal__dialog {
        position: relative;
        //width: auto;
        margin: 192px 16px;
        transition: ease .25s, ease opacity .1s;

        /* свойства для блока, содержащего контент модального окна */
        .ProfileModal__content {
            position: relative;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            //background-color: white;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            border-radius: 12px;
            background-color: #f7f7f7;
            outline: 0;
            //padding: 42px;
            //width: 460px;
            //min-height: 12px;

            /* свойства для блока, содержащего основное содержимое окна */
            .Modal__body {
                position: relative;
                -webkit-box-flex: 1;
                -webkit-flex: 1 1 auto;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                overflow: hidden;

                .Body__menu {
                    width: 148px;
                    vertical-align: top;
                    padding: 42px 36px;
                    background-color: #f7f7f7;
                    border-radius: 12px 0 0 12px;
                    display: inline-block;

                    .Menu__container {

                        .Menu__link {

                            font-family: Roboto, sans-serif;
                            font-weight: 900;
                            font-size: 17px;
                            color: #2A3E4C;
                            cursor: pointer;
                            margin-bottom: 8px;
                            opacity: .25;
                            transition: ease 0.3s;

                            &:hover {
                                opacity: 1;
                                margin-left: 4px;
                            }

                            &.active {
                                opacity: 1;
                                cursor: default;

                                &:hover {
                                    opacity: 1;
                                    margin-left: 0;
                                }

                                .a {
                                    background-color: rgba(42, 62, 76, 0.05);
                                }
                            }

                            .a {
                                padding: 8px 12px;
                                border-radius: 5px;
                            }
                        }
                    }
                }

                .Body__form {
                    vertical-align: top;
                    width: 460px;
                    display: inline-block;
                    padding: 42px;
                    background-color: white;
                    border-radius: 0 12px 12px 0;
                    height: 100%;

                    .Body__loading {
                        padding: 96px;
                    }
                }
            }

            /* свойства для заголовка модального окна */
            .Modal__close {
                position: absolute;
                top: 42px;
                right: 42px;
                height: 21px;
                width: 21px;

                .Button__close {
                    height: 21px;
                    width: 21px;
                    color: #000;
                    opacity: .25;
                    cursor: pointer;
                    transition: ease .2s;

                    &.icon-close {
                        height: 21px;
                        width: 21px;
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS4yMTMiIGhlaWdodD0iMjEuMjEzIiB2aWV3Qm94PSIwIDAgMjEuMjEzIDIxLjIxMyI+ICA8ZyBpZD0iR3JvdXBfOTA5IiBkYXRhLW5hbWU9Ikdyb3VwIDkwOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTU1Mi4yNSAtMTAzOC43NCkgcm90YXRlKDQ1KSI+ICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMTE4MCIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMTE4MCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjYiIHJ4PSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMTI4IDM0MSkiIGZpbGw9IiMyQTNFNEMiLz4gICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8xMTgxIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxMTgxIiB3aWR0aD0iNiIgaGVpZ2h0PSIyNCIgcng9IjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExMzcgMzMyKSIgZmlsbD0iIzJBM0U0QyIvPiAgPC9nPjwvc3ZnPg==);
                        background-repeat: no-repeat;
                    }

                    /* свойства для кнопки "Закрыть" при нахождении её в фокусе или наведении */
                    &:focus, &:hover {
                        opacity: .5;
                    }
                }
            }
        }
    }
}
