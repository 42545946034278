.StudentTrainingCourses {

    .StudentTrainingCourses__body {

        .Body__title {
            color: #2a3e4c;
            font-family: Roboto, sans-serif;
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 24px;
        }

        .Body__hr {
            width: 100%;
            height: 2px;
            margin-bottom: 42px;
            border-radius: 1px;
            background-color: #EAEAEA;
        }

        .Body__list {
            width: 100%;
            margin-bottom: 42px;


            .List__box {
                border-radius: 12px;
                transition: ease .25s;
                margin-bottom: 24px;

                &:hover {
                    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.07);
                }

                .Box__header {
                    background-color: #0061ff;
                    //padding: 56px 24px 24px 24px;
                    padding: 24px;
                    border-radius: 12px 12px 0 0;

                    .Header__title {
                        cursor: pointer;
                        color: white;
                        font-size: 24px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 700;
                    }
                }

                .Box__footer {
                    padding: 24px;
                    background-color: white;
                    border-radius: 0 0 12px 12px;
                }
            }

            .List__item {
                height: 53px;
                transition: ease .25s;
                color: #2a3e4c;
                font-family: Roboto, sans-serif;
                font-size: 19px;
                font-weight: 700;
                background-color: #EEEEEE;
                border-radius: 7px;
                padding: 17px 32px 16px 24px;
                cursor: pointer;
                margin-bottom: 24px;

                &:hover {
                    background-color: #ffffff;
                    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.07);
                }

                .Item__wrapper {
                    width: 100%;

                    .Item__left {
                        float: left;
                        display: inline-block;
                    }

                    .Item__right {
                        padding-top: 1px;
                        float: right;
                        display: inline-block;
                    }
                }

                .Item__block {
                    font-family: 'Roboto', sans-serif;
                    font-size: 19px;
                    font-weight: 700;
                    color: #2A3E4C;

                    &_inline {
                        display: inline-block;
                    }

                    &_margin-left {
                        margin-left: 32px;
                    }

                    &_padding {
                        padding-top: 8px;
                    }
                }

                .Item__label {
                    color: #2A3E4C;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    opacity: .25;

                    &_inline {
                        font-size: 19px;
                        vertical-align: super;
                        display: inline-block;
                    }

                    &_block {
                        font-size: 17px;
                        display: block;
                        margin-bottom: 6px;
                    }

                    &_mini {
                        padding-top: 2px;
                        font-size: 15px;
                        font-weight: 500;
                        margin-bottom: 6px;
                    }
                }

                .Item__Text {
                    display: inline-block;
                    vertical-align: super;
                    font-family: 'Roboto', sans-serif;
                    font-size: 19px;
                    font-weight: 700;
                    color: #2A3E4C;
                    font-size: 24px;
                    font-weight: 900;
                }

                .Item__hr {
                    margin: 0 16px;

                    &_margin-32 {
                        margin: 0 32px;
                    }

                    &_vertical {
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUOE+F1O1LwkAcwPG7zamBhWmERIhIREiEoHPm9sK3lZp/avgQ1CtfOJ9HEiHhCxEJk5AYZaZuu4teFLXtznv9efH7cd87CNYcQcrHDKQtO86PR0izoVDaHdj3JjHW9aZcqlIxz58HWJfzSEfatFMrP1CxIFxGIAd3lxj17+TCmI7FjAShwzF5UhvDYWVBxJFI2rPl88YxNhZNudj43o2IBeFsH3KuA80wJkq9+LgG504gx/jmaN67r92+0DDDp3ISwBi26+8yABWdiKPRvNftAVGE0awlFzo/d2E7My9dhFnABQ2gjdrV8oCKE2IuzkDGs5iBbrd7pVJw2sGfbooAQtyuFaoAAETEsVh2h9tgjw0E1Hbtqvu3HcvMicT5IeN07mlYHyhyaUTFgphNQsi6317VTq9XmRGxOUlzvv/GMCdJxeYk6diUJBHbJUnEMTET5KAjjFarcat13bd7m78L8ql8lGWAV/s0HhSlOKVh2yRtxyAlaYtJSdpiUpI2mJykBdOStGBakhZMS9KCv7P0+13binLzvO77/QK1APGlKBSmpwAAAABJRU5ErkJggg==);
                        background-repeat: no-repeat;
                        height: 32px;
                        width: 11px;
                    }

                    &_inline {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
.Block__Item {
    margin-bottom: 36px;

    .Item__wrapper {
        width: 100%;

        .Item__left {
            float: left;
            display: inline-block;
        }

        .Item__right {
            float: right;
            display: inline-block;
        }
    }

    .Item__block {
        font-family: 'Roboto', sans-serif;
        font-size: 19px;
        font-weight: 700;
        color: #2A3E4C;

        &_inline {
            display: inline-block;
        }

        &_margin-right {
            margin-right: 56px;
        }

        &_padding {
            padding-top: 8px;
        }
    }

    .Item__label {
        color: #2A3E4C;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        opacity: .25;

        &_inline {
            font-size: 19px;
            vertical-align: super;
            display: inline-block;
        }

        &_block {
            font-size: 17px;
            display: block;
            margin-bottom: 6px;
        }

        &_mini {
            padding-top: 2px;
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 6px;
        }
    }

    .Item__Text {
        display: inline-block;
        vertical-align: super;
        font-family: 'Roboto', sans-serif;
        font-size: 19px;
        font-weight: 700;
        color: #2A3E4C;
    }

    .Item__hr {
        margin: 0 16px;

        &_margin-32 {
            margin: 0 32px;
        }

        &_vertical {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAgCAYAAADEx4LTAAAABHNCSVQICAgIfAhkiAAAAZ5JREFUOE+F1O1LwkAcwPG7zamBhWmERIhIREiEoHPm9sK3lZp/avgQ1CtfOJ9HEiHhCxEJk5AYZaZuu4teFLXtznv9efH7cd87CNYcQcrHDKQtO86PR0izoVDaHdj3JjHW9aZcqlIxz58HWJfzSEfatFMrP1CxIFxGIAd3lxj17+TCmI7FjAShwzF5UhvDYWVBxJFI2rPl88YxNhZNudj43o2IBeFsH3KuA80wJkq9+LgG504gx/jmaN67r92+0DDDp3ISwBi26+8yABWdiKPRvNftAVGE0awlFzo/d2E7My9dhFnABQ2gjdrV8oCKE2IuzkDGs5iBbrd7pVJw2sGfbooAQtyuFaoAAETEsVh2h9tgjw0E1Hbtqvu3HcvMicT5IeN07mlYHyhyaUTFgphNQsi6317VTq9XmRGxOUlzvv/GMCdJxeYk6diUJBHbJUnEMTET5KAjjFarcat13bd7m78L8ql8lGWAV/s0HhSlOKVh2yRtxyAlaYtJSdpiUpI2mJykBdOStGBakhZMS9KCv7P0+13binLzvO77/QK1APGlKBSmpwAAAABJRU5ErkJggg==);
            background-repeat: no-repeat;
            height: 32px;
            width: 11px;
        }

        &_inline {
            display: inline-block;
        }
    }
}

.Course__info {
    .Item__index {
        display: inline-block;
        //position: absolute;
        //top: -12px;
        //right: 24px;
        //color: #2A3E4C;
        margin-bottom: 12px;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        //text-transform: uppercase;
        //opacity: .25;
        background-color: #2A3E4C;
        color: white;
        padding: 6px 8px 5px;
        border-radius: 5px;
        //opacity: .75;
    }
}